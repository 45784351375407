<template>
  <div style="background-color: #f0f0f7">
    <div style="margin-left: 1%">
      <p
        style="
          font-size: 25px;
          color: #43425d;
          padding: 18px;
          margin-top: 0.2rem;
        "
      >
        {{ $t("notifications") }}
      </p>
    </div>
    <v-row style="margin-right: 0; margin-left: -2px">
      <v-col cols="12" md="12" sm="12">
        <v-card
          class="pt-5 mb-5"
          style="
            margin-bottom: 5rem;
            margin-left: 1%;
            margin-right: 0.3%;
            height: auto;
            min-height: 700px;
          "
        >
          <div>
            <v-row style="margin-top: -0.5rem">
              <v-col cols="12">
                <div style="float: left; margin-left: 15px">
                  <v-icon color="#F89D1F" size="20">mdi-bell</v-icon>
                </div>
                <div style="float: left">
                  <p
                    class=""
                    text
                    style="cursor: pointer; padding-left: 9px; color: #f89d1f"
                  >
                    {{ $t("notifications") }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  router
                  :to="item.route"
                  v-for="(item, i) in requestData"
                  :key="i"
                  @click="
                    sendDataWithParams(
                      item.wF_ID,
                      item.feedback_ID,
                      item.notificationID,
                      i,
                      item.seen,
                      item
                    )
                  "
                  v-bind:style="{ background: colorList[i] }"
                  style="margin-top: 1px"
                >
                  <v-row style="width: 100%; text-align: left">
                    <v-col
                      v-if="item.senderImage"
                      cols="1"
                      class="pa-0 ma-0 pt-2"
                    >
                      <img
                        :src="item.senderImage"
                        class="profileimagefornoti"
                      />
                    </v-col>
                    <v-col
                      v-if="!item.senderImage"
                      cols="1"
                      class="pa-0 ma-0 pt-2"
                    >
                      <img
                        src="@/assets/profile.svg"
                        class="profileimagefornoti"
                      />
                    </v-col>
                    <v-col cols="8" class="pt-7 pl-4">
                      <div
                        class="text"
                        style="
                          text-align: left;
                          text-transform: none;
                          font-weight: normal;
                        "
                      >
                        {{ item.senderName }}
                        {{ Checkingtranslate(item.description) }}
                      </div>
                    </v-col>
                    <v-col
                      cols="3"
                      class="text ma-0 pt-7 pl-1"
                      style="text-align: right; :10px "
                    >
                      {{ convertUTCDateToLocalDate(item.noti_date) }}
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template> 

<script>
import axios from "axios";
import store from "@/store"; /*  eslint-disable-line */
import enurl from "@/api/environment";
export default {
  components: {},
  data() {
    return {
      customImgHeight: "100%",
      rememberme: "false",
      showLists: false,
      url: enurl.apiUrl,
      requestData: [],
      profileimage: null,
      colorList: [],
    };
  },
  mounted() {
    this.GetrequestList();
  },
  computed: {
    Checkingtranslate() {
      return function (value) {
        return value == "has sent a feedback"
          ? this.$t("Sentfeedback")
          : value == "has replied to a feedback"
          ? this.$t("Repliedfeedback")
          : this.$t("Replyyourfeedback");
      };
    },
  },
  methods: {
    convertUTCDateToLocalDate(date) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);

      let output = null;

      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }

      return output;
    },
    formatDate(date) {
      // let finaldate = date.slice(0, 10).split("-");
      let spliteDateAndTime = date.split("T");
      let Date = spliteDateAndTime[0].split("-");
      let time = spliteDateAndTime[1].split(":");
      return (
        Date[0] + "/" + Date[1] + "/" + Date[2] + " " + time[0] + ":" + time[1]
      );
    },
    showListChange() {
      let self = this;
      self.showLists = !self.showLists;
    },
    async GetrequestList() {
      let self = this;
      self.requestData = [];
      self.colorList = [];
      await axios
        .get(`${self.url}NotificationInfo/GetLatestNotiProductAdmin`)
        .then(function (response) {
          let temps = response.data.data;

          temps
            .slice()
            .reverse()
            .forEach(function (item) {
              self.requestData.push(item);
              if (item.seen == false) {
                self.colorList.push("#D0D0D0");
              } else {
                self.colorList.push("white");
              }
            });
        });
    },

    async sendDataWithParams(
      input,
      Feedback,
      notificationID,
      index,
      status,
      item
    ) {
      //await this.changeState(index, status);

      let temp = {
        notificationID: notificationID,
      };
      if (Feedback == 0) {
        await axios
          .post(`${this.url}NotificationInfo/EditnotificationState`, temp)
          .then(function () {});

        if (item.wF_Name === "Feedback") {
          this.$router.push({
            name: "HelpAndFeedbackNotification",
            params: {
              notiString: input,
              condition:
                item.description === "has sent a feedback" ? "Sent" : "Replied",
            },
          }).catch(()=>{});
        }
      } else {
        await axios
          .post(`${this.url}NotificationInfo/EditnotificationState`, temp)
          .then(function () {});

        if (item.wF_Name === "Feedback") {
          this.$router.push({
            name: "HelpAndFeedbackNotification",
            params: {
              notiString: Feedback,
              condition: (item.description = "Replied"),
            },
          }).catch(()=>{});
        }
      }
    },
  },
};
</script> 

<style scoped>
/* .list:nth-of-type(even) {
    background-color: #fff !important;
} */
.profileimagefornoti {
  border: 1px solid #43425d;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  margin-top: 15px;
}

.text {
  font-size: 13px;
}
@media (max-width: 370px) and (max-height: 640px) {
  .text {
    font-size: 13px;
  }
}

@media (min-width: 410px) and (min-height: 720px) {
  .text {
    font-size: 13px;
  }
}

@media (max-width: 320px) and (max-height: 570px) {
  .text {
    font-size: 13px;
  }
}

@media (min-width: 370px) and (min-height: 660px) {
  .text {
    font-size: 13px;
  }
}

@media (min-width: 760px) and (min-height: 1020px) {
  .text {
    font-size: 13px;
  }
  .profileimagefornoti {
    border: 1px solid #43425d;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    margin-top: 15px;
    margin-left: 20px;
  }
}

@media (min-width: 1240px) and (min-height: 700px) {
  .text {
    font-size: 13px;
  }
  .profileimagefornoti {
    border: 1px solid #43425d;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    margin-top: 15px;
    margin-left: 20px;
  }
}

@media (min-width: 1000px) and (min-height: 1300px) {
  .text {
    font-size: 17px;
  }
  .profileimagefornoti {
    border: 1px solid #43425d;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    margin-top: 15px;
    margin-left: 20px;
  }
}
</style>