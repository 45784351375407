var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f0f0f7"}},[_c('div',{staticStyle:{"margin-left":"1%"}},[_c('p',{staticStyle:{"font-size":"25px","color":"#43425d","padding":"18px","margin-top":"0.2rem"}},[_vm._v(" "+_vm._s(_vm.$t("notifications"))+" ")])]),_c('v-row',{staticStyle:{"margin-right":"0","margin-left":"-2px"}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"pt-5 mb-5",staticStyle:{"margin-bottom":"5rem","margin-left":"1%","margin-right":"0.3%","height":"auto","min-height":"700px"}},[_c('div',[_c('v-row',{staticStyle:{"margin-top":"-0.5rem"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"float":"left","margin-left":"15px"}},[_c('v-icon',{attrs:{"color":"#F89D1F","size":"20"}},[_vm._v("mdi-bell")])],1),_c('div',{staticStyle:{"float":"left"}},[_c('p',{staticStyle:{"cursor":"pointer","padding-left":"9px","color":"#f89d1f"},attrs:{"text":""}},[_vm._v(" "+_vm._s(_vm.$t("notifications"))+" ")])])])],1),_c('v-list',[_c('v-list-item-group',_vm._l((_vm.requestData),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"margin-top":"1px"},style:({ background: _vm.colorList[i] }),attrs:{"router":"","to":item.route},on:{"click":function($event){return _vm.sendDataWithParams(
                    item.wF_ID,
                    item.feedback_ID,
                    item.notificationID,
                    i,
                    item.seen,
                    item
                  )}}},[_c('v-row',{staticStyle:{"width":"100%","text-align":"left"}},[(item.senderImage)?_c('v-col',{staticClass:"pa-0 ma-0 pt-2",attrs:{"cols":"1"}},[_c('img',{staticClass:"profileimagefornoti",attrs:{"src":item.senderImage}})]):_vm._e(),(!item.senderImage)?_c('v-col',{staticClass:"pa-0 ma-0 pt-2",attrs:{"cols":"1"}},[_c('img',{staticClass:"profileimagefornoti",attrs:{"src":require("@/assets/profile.svg")}})]):_vm._e(),_c('v-col',{staticClass:"pt-7 pl-4",attrs:{"cols":"8"}},[_c('div',{staticClass:"text",staticStyle:{"text-align":"left","text-transform":"none","font-weight":"normal"}},[_vm._v(" "+_vm._s(item.senderName)+" "+_vm._s(_vm.Checkingtranslate(item.description))+" ")])]),_c('v-col',{staticClass:"text ma-0 pt-7 pl-1",staticStyle:{"text-align":"right","":"10px"},attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.convertUTCDateToLocalDate(item.noti_date))+" ")])],1)],1)}),1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }