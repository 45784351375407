<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px;margin-top: 22px;" class="headertext">
        {{ $t("companyManagement") }}
      </p>
    </div>

    <div class="dialogmargin">
      <v-tabs class="mt-2" grey--text background-color="#f0f0f7" slider-color="#F99D20" show-arrows>
        <v-tab class="text-capitalize" @click="orginalOne(1)" style="border-radius: 10px 10px 5px 5px; width: 160px; font-size: 16px; background-color: #ffffff">
          {{ $t("Running")}}
        </v-tab>
        <v-tab class="text-capitalize" @click="orginalOne(2)" style="border-radius: 10px 10px 5px 5px; width: 160px; font-size: 16px; background-color: #ffffff">
          {{ $t("Removed") }}
        </v-tab>
        <!-- Button Export-Excel & New company -->
        <v-col style="background-color: #f0f0f7" class="d-flex align-center flex-row-reverse pr-0 mr-0">
          <v-col class="d-inline-flex mr-0 pr-0">
            <v-spacer></v-spacer>
            <v-btn @click="dialogExport = true" color="#2E7D32" outlined style="width: 180px">
              <img width="20" height="20" :src="images.excel" alt class="mr-3" />
              <!-- <export-excel :data="LogList" name="Log.xls">Export XLS</export-excel> -->
              {{ $t("exportExcel") }}
            </v-btn>
            <v-btn width="180px" color="#F99D20" class="white--text ml-4 mr-0" router
              :to="{ name: 'addcompany', params: { addcompany: 'addcompany' } }">+ {{ $t("newCompany") }}</v-btn>
          </v-col>
        </v-col>

        <v-tab-item>
          <v-card>
            <v-row class="ml-6 mb-5 pt-5">{{ $t("Search") }}</v-row>
            <v-row class="ml-5 mr-5">
              <div style="border: solid 1px #707070; width:100%; border-radius: 8px;padding: 1rem;">
                <v-row style="padding-top: 1rem;">
                  <v-col cols="12" sm="5" md="6" lg="6" class="pb-0" style="width:100%;">
                    <v-row>
                      <v-col class="ml-5 mt-2 thealign" cols="12" sm="8" md="6" lg="3" style="width:100%;">{{ $t("companyCode") }}:</v-col>
                      <v-col class="ml-5 thewick" cols="12" sm="8" md="8" lg="6" style="width:100%;">
                        <v-text-field autocomplete="off" v-model="searchCode" style="max-width: 320px;"
                        outlined dense color="#f99d20" :placeholder="$t('companyCode')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="7" md="6" lg="6" class="pb-0" style="width:100%;">
                    <v-row>
                      <v-col class="mt-2 pl-0 theanoleft theleft" cols="12" sm="8" md="6" lg="3" style="width:100%;">{{ $t("companyName") }}
                        :</v-col>
                      <v-col class="ml-5 thewick" cols="12" sm="8" md="8" lg="8" style="width:100%;">
                        <v-text-field autocomplete="off" v-model="searchName" style="max-width: 320px;"
                        outlined dense color="#f99d20" :placeholder="$t('companyName')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="padding-top: 1rem;">
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0" style="width:100%;">
                    <v-row>
                      <v-col class="ml-5 mt-4 thealign" cols="12" sm="6" md="6" lg="3" style="width:100%;">{{ $t("Package") }}
                        :</v-col>
                      <v-col class="ml-5 mt-2 thewick" justify-end cols="12" sm="8" md="8" lg="6" style="width:100%;">
                        <v-select class="text empID" style="border-radius: 5px; max-width: 320px;" :items="packageList"
                          v-model="searchPkg" :placeholder="$t('selectpkg')" outlined dense clearable append-icon="mdi-chevron-down"
                          color="#F99D20"></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0" style="width:100%;">
                    <v-row>
                      <v-col class="mt-4 pl-0 pr-0 theleft" cols="12" sm="12" md="12" lg="3" xl="3" style="width:100%;">{{ $t("registerDateC") }}
                        :</v-col>
                      <v-col cols="12" sm="12" md="12" lg="9" xl="8" style="width:100%;">
                        <v-row class="mb-2">
                          <v-col cols="12" sm="12" md="12" lg="6" xl="5" style="width:100%;">
                            <v-row class="ml-1">
                              <p class="mt-2 ml-5">{{ $t("from") }}</p>
                              <date-picker v-bind:clearable="false" v-model="regDateFrom" 
                                format="DD/MM/YYYY"
                                :disabled-date="(date) =>
                                  date > new Date()
                                  " placeholder="DD/MM/YYYY" 
                                  class="fromdate custom-vuedatepicker2" style="
                                margin-left: 15px;
                                width: 180px;
                                max-height: 38px;
                                border-radius: 5px;
                              ">
                                <!-- <template slot="icon-calendar">
                                  <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                                </template> -->
                              </date-picker>
                            </v-row>
                          </v-col>
                          <v-col cols="12" sm="12" md="12" lg="6" xl="5" style="width:100%;">
                            <v-row class="ml-1">
                              <p class="mt-2 ml-5">{{ $t("to") }}</p>
                              <date-picker v-bind:clearable="false" v-model="regDateTo"
                              class="fromdate custom-vuedatepicker2" format="DD/MM/YYYY"
                                width="100%" :disabled-date="(date) =>
                                  date < BeforeFromDate()
                                  " placeholder="DD/MM/YYYY" style="
                                margin-left: 20px;
                                width: 180px;
                                max-height: 38px;
                                border-radius: 5px;
                              ">
                                <!-- <template slot="icon-calendar">
                                  <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                                </template> -->
                              </date-picker>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mb-5 mr-5" justify="end">
                  <v-btn color="#F99D20" @click="Search1()" class="white--text text-capitalize theleft mr4" width="120"
                          max-height="35">
                          <v-icon class="pr-1">mdi-magnify</v-icon>{{
                            $t("Search")
                          }}
                        </v-btn>
                </v-row>
              </div>
            </v-row>
            <v-data-table style="margin-left: 10px; margin-right: 40px;padding-top: 1.5rem;" :headers="headers" :items="CompanyList"
              :page.sync="page" hide-default-header :items-per-page="itemsPerPage" @page-count="pageCount = $event"
              hide-default-footer>
              <template v-slot:header>
                <thead>
                  <tr>
                    <th style="cursor: pointer; min-width: 180px !important" class="b-text"
                      @click="sortFun('companyCode')">
                      {{ headers[0].text }}
                      <v-icon small v-if="!companyCode">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyCode">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 180px !important" class="b-text"
                      @click="sortFun('companyName')">
                      {{ headers[1].text }}
                      <v-icon small v-if="!companyName">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyName">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 180px !important" class="b-text"
                      @click="sortFun('register_Date')">
                      {{ headers[2].text }}
                      <v-icon small v-if="!register_Date">mdi-arrow-down</v-icon>
                      <v-icon small v-if="register_Date">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 180px !important" class="b-text"
                      @click="sortFun('expired_Date')">
                      {{ headers[3].text }}
                      <v-icon small v-if="!expired_Date">mdi-arrow-down</v-icon>
                      <v-icon small v-if="expired_Date">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 180px !important" class="b-text" @click="sortFun('pkgName')">
                      {{ headers[4].text }}
                      <v-icon small v-if="!pkgName">mdi-arrow-down</v-icon>
                      <v-icon small v-if="pkgName">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 180px !important" class="b-text"
                      @click="sortFun('contact_Name')">
                      {{ headers[5].text }}
                      <v-icon small v-if="!contact_Name">mdi-arrow-down</v-icon>
                      <v-icon small v-if="contact_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 190px !important" class="b-text"
                      @click="sortFun('tele_Number')">
                      {{ headers[6].text }}
                      <v-icon small v-if="!tele_Number">mdi-arrow-down</v-icon>
                      <v-icon small v-if="tele_Number">mdi-arrow-up</v-icon>
                    </th>
                    <th @click="sortFun('company_Email')" class="b-text"
                      style="cursor: pointer; min-width: 200px !important">
                      {{ headers[7].text }}
                      <v-icon small v-if="!company_Email">mdi-arrow-down</v-icon>
                      <v-icon small v-if="company_Email">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 180px !important" class="b-text" @click="sortFun('status')">
                      {{ headers[8].text }}
                      <v-icon small v-if="!status">mdi-arrow-down</v-icon>
                      <v-icon small v-if="status">mdi-arrow-up</v-icon>
                    </th>
                    <th style="cursor: pointer; min-width: 100px !important">{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.companyCode }}</td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ datestyle(item.register_Date) }}</td>
                  <td>{{ datestyle(item.expired_Date) }}</td>
                  <td>{{ item.pkgName }}</td>
                  <td>{{ item.contact_Name }}</td>
                  <td>{{ item.tele_Number }}</td>
                  <td>{{ item.company_Email }}</td>
                  <td class="pl-1">
                    <v-btn rounded width="100" text :style="{
                      color: item.status == true ? '#22A534' : ' #FF6565',
                      background: item.status == true ? '#C6E8CB' : '#FFD8D8',
                    }">{{ item.status == true ? "Active" : "Inactive" }}</v-btn>
                  </td>
                  <td class="pl-1">
                    <v-btn icon @click="goNewCompany(item.company_ID)">
                      <img :src="imagesbtn.edit" class="editIcon" />
                    </v-btn>
                    <v-btn icon @click="DeleteDialog(item.company_ID)">
                      <img :src="imagesbtn.delete" class="deleteIcon"/>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-spacer></v-spacer>
            <div>
              <CustomPaginationComponent
                :total-items="totalItems"
                :page.sync="page"
                :itemsPerPage.sync="itemsPerPage"
                @update:page="onPageChanged"
                @update:itemsPerPage="onItemsPerPageChanged"
                :showPageText="true"/>
            </div>
          </v-card>
        </v-tab-item>
        
        <v-tab-item>
          <v-card>
            <v-row class="ml-6 mb-5 pt-5">{{ $t("Search") }}</v-row>
            <v-row class="ml-5 mr-5">
              <div style="border: solid 1px #707070; width:100%; border-radius: 8px;padding: 1rem;">
                <v-row style="padding-top: 1rem;">
                  <v-col cols="12" sm="5" md="6" lg="6" class="pb-0" style="width:100%;">
                    <v-row>
                      <v-col class="ml-5 mt-2 thealign" cols="12" sm="8" md="6" lg="3" style="width:100%;">{{ $t("companyCode") }}:</v-col>
                      <v-col class="ml-5 thewick" cols="12" sm="8" md="8" lg="6" style="width:100%;">
                        <v-text-field autocomplete="off" v-model="searchDelCode" style="max-width: 320px;"
                        outlined dense color="#f99d20" :placeholder="$t('companyCode')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="7" md="6" lg="6" class="pb-0" style="width:100%;">
                    <v-row>
                      <v-col class="mt-2 theanoleft theleft" cols="12" sm="8" md="6" lg="3" style="width:100%;">{{ $t("companyName") }}
                        :</v-col>
                      <v-col class="ml-5 thewick" cols="12" sm="8" md="8" lg="8" style="width:100%;">
                        <v-text-field autocomplete="off" v-model="searchDelName" style="max-width: 320px;"
                        outlined dense color="#f99d20" :placeholder="$t('companyName')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="padding-top: 1rem;">
                  <v-col cols="12" sm="6" md="6" lg="6" class="pt-0" style="width:100%;">
                    <v-row>
                      <v-col class="ml-5 mt-4 thealign" cols="12" sm="6" md="6" lg="3" style="width:100%;">{{ $t("Package") }}
                        :</v-col>
                      <v-col class="ml-5 mt-2 thewick" cols="12" sm="8" md="8" lg="6" style="width:100%;">
                        <v-select class="text empID " style="border-radius: 5px; min-width: 150px; max-width: 320px;" :items="packageList"
                          v-model="searchDelPkg" :placeholder="$t('selectpkg')" outlined dense clearable append-icon="mdi-chevron-down"
                          color="#F99D20"></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" class="pt-0" style="width:100%;">
                    <v-row>
                      <v-col class="mt-4 pl-0 pr-0 theleft" cols="12" sm="12" md="12" lg="3" xl="3" style="width:100%;">{{ $t("registerDateC") }}
                        :</v-col>
                      <v-col cols="12" sm="12" md="12" lg="9" xl="8" style="width:100%;">
                        <v-row class="mb-2">
                          <v-col cols="12" sm="12" md="12" lg="6" xl="5" style="width:100%;">
                            <v-row class="ml-1">
                              <p class="mt-2 ml-5">{{ $t("from") }}</p>
                              <date-picker v-bind:clearable="false" v-model="delDateFrom" format="DD/MM/YYYY"
                                :disabled-date="(date) =>
                                date > new Date()
                                " placeholder="DD/MM/YYYY"
                                class="fromdate custom-vuedatepicker2" style="
                                margin-left: 15px;
                                width: 180px;
                                max-height: 38px;
                                border-radius: 5px;
                              ">
                              </date-picker>
                            </v-row>
                          </v-col>
                          <v-col cols="12" sm="12" md="12" lg="6" xl="5" style="width:100%;">
                            <v-row class="ml-1">
                              <p class="mt-2 ml-5">{{ $t("to") }}</p>
                              <date-picker v-bind:clearable="false" v-model="delDateTo" format="DD/MM/YYYY"
                                width="100%" :disabled-date="(date) =>
                                date < BeforeFromDelDate()
                                " placeholder="DD/MM/YYYY"
                                class="fromdate custom-vuedatepicker2" style="
                                margin-left: 15px;
                                width: 180px;
                                max-height: 38px;
                                border-radius: 5px;
                              ">
                                <!-- <template slot="icon-calendar">
                                  <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
                                </template> -->
                              </date-picker>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mb-5 mr-5" justify="end">
                  <v-btn color="#F99D20" @click="Search2()" class="white--text text-capitalize theleft mr4" width="120"
                    max-height="35">
                    <v-icon class="pr-1">mdi-magnify</v-icon>{{
                      $t("Search")
                    }}
                  </v-btn>
                </v-row>
              </div>
            </v-row>
            <v-data-table style="margin-left: 10px; margin-right: 40px;padding-top: 1.5rem;" :headers="removeheaders"
              :items="Deletecompanylist" :page.sync="removepage" hide-default-header :items-per-page="itemsPerPage"
              @page-count="removepagecount = $event" hide-default-footer>
              <template v-slot:header="{ props: {} }">
                <thead>
                  <tr>
                    <th style="min-width: 180px !important" @click="sortdelete('companyCode')">
                      {{ removeheaders[0].text }}
                      <v-icon small v-if="!companyCode">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyCode">mdi-arrow-up</v-icon>
                    </th>
                    <th style="min-width: 180px !important" @click="sortdelete('companyName')">
                      {{ removeheaders[1].text }}
                      <v-icon small v-if="!companyName">mdi-arrow-down</v-icon>
                      <v-icon small v-if="companyName">mdi-arrow-up</v-icon>
                    </th>
                    <th style="min-width: 180px !important" @click="sortdelete('register_Date')">
                      {{ headers[2].text }}
                      <v-icon small v-if="!register_Date">mdi-arrow-down</v-icon>
                      <v-icon small v-if="register_Date">mdi-arrow-up</v-icon>
                    </th>
                    <th style="min-width: 180px !important" @click="sortdelete('expired_Date')">
                      {{ headers[3].text }}
                      <v-icon small v-if="!expired_Date">mdi-arrow-down</v-icon>
                      <v-icon small v-if="expired_Date">mdi-arrow-up</v-icon>
                    </th>
                    <th style="min-width: 180px !important" class="b-text" @click="sortdelete('pkgName')">
                      {{ headers[4].text }}
                      <v-icon small v-if="!pkgName">mdi-arrow-down</v-icon>
                      <v-icon small v-if="pkgName">mdi-arrow-up</v-icon>
                    </th>
                    <th style="min-width: 180px !important" class="b-text"
                      @click="sortdelete('contact_Name')">
                      {{ headers[5].text }}
                      <v-icon small v-if="!contact_Name">mdi-arrow-down</v-icon>
                      <v-icon small v-if="contact_Name">mdi-arrow-up</v-icon>
                    </th>
                    <th style="min-width: 190px !important" @click="sortdelete('tele_Number')">
                      {{ removeheaders[6].text }}
                      <v-icon small v-if="!tele_Number">mdi-arrow-down</v-icon>
                      <v-icon small v-if="tele_Number">mdi-arrow-up</v-icon>
                    </th>
                    <th style="min-width: 200px !important" @click="sortdelete('company_Email')">
                      {{ removeheaders[7].text }}
                      <v-icon small v-if="!company_Email">mdi-arrow-down</v-icon>
                      <v-icon small v-if="company_Email">mdi-arrow-up</v-icon>
                    </th>
                    
                    <th style="min-width: 180px !important">{{ $t("status") }}</th>
                    <th style="min-width: 100px !important">{{ $t("action") }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.companyCode }}</td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ datestyle(item.register_Date) }}</td>
                  <td>{{ datestyle(item.expired_Date) }}</td>
                  <td>{{ item.pkgName }}</td>
                  <td>{{ item.contact_Name }}</td>
                  <td>{{ item.tele_Number }}</td>
                  <td>{{ item.company_Email }}</td>
                  <td>
                    <v-btn width="100" class="white--text delbtn" text>{{
                      $t("delete")
                    }}</v-btn>
                  </td>
                  <td>
                    <v-btn icon color="white" @click="detailcompany(item)">
                      <img width="40" height="40" :src="viewicon" alt />
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div>
              <CustomPaginationComponent
                :total-items="DeltotalItems"
                :page.sync="removepage"
                :itemsPerPage.sync="itemsPerPage"
                @update:page="onPageChangedDel"
                @update:itemsPerPage="onItemsPerPageChangedDel"
                :showPageText="true"/>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs>

      <!-- delete  dialog -->
      <v-dialog v-model="dialog" persistent max-width="532">
        <v-card style="border-radius: 15px">
          <v-card-title>
            <p style="margin-top: 10px; margin-bottom: -30px">
              {{ $t("deleteitem") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col align="center" cols="12">
                  <p style="font-size: 16px; color: #444444" class="pt-4">
                    {{ $t("deleteconfirm") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions style="margin-right: 3%" class="pb-6">
            <v-spacer></v-spacer>
            <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="dialog = false" text>{{ $t("cancel")
            }}</v-btn>
            <v-btn color="#FF2727" class="mr-2 white--text text-capitalize" width="120" max-height="35"
              @click="DeleteCompany()">{{ $t("delete") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- detail dialog -->
      <v-dialog v-model="detaildialog" persistent width="800">
        <v-card height="100%">
          <v-card-title>
            <p>{{ $t("Company") }}</p>
            <v-spacer></v-spacer>
            <v-btn style="margin-top: -10px" @click="detaildialog = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <p class="ml-6" style="color: #f99d20">
            {{ $t("generalInformation") }}
          </p>
          <hr class="ml-5 mr-4" />
          <v-row class="ml-5 mr-2 mt-3">
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("companyName") }}</p>
              <p class="mt-2">{{ deletecompany.companyname }}</p>
            </v-col>
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("companyCode") }}</p>
              <p class="mt-2">{{ deletecompany.companycode }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-2 mt-3">
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("telephone_number") }}</p>
              <p class="mt-2">{{ deletecompany.teleph }}</p>
            </v-col>
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("email") }}</p>
              <p class="mt-2">{{ deletecompany.email }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-2 mt-3">
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("country") }}</p>
              <p class="mt-2">{{ deletecompany.country }}</p>
            </v-col>
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("city") }}</p>
              <p class="mt-2">{{ deletecompany.city }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-2 mt-3">
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("address") }}</p>
              <p class="mt-2">{{ deletecompany.address }}</p>
            </v-col>
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("expired_date") }}</p>
              <p class="mt-2">{{ deletecompany.expiredate }}</p>
            </v-col>
          </v-row>
          <p class="ml-6 mt-3" style="color: #f99d20">
            {{ $t("LoginCredentials") }}
          </p>
          <hr class="ml-4 mr-4" />
          <v-row class="ml-5 mr-2 mt-2">
            <v-col md="5" col="6">
              <p class="mytitle">{{ $t("username") }}</p>
              <p class="mt-2">{{ deletecompany.userid }}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      
      <!-- Dialog Export File Excel -->
      <v-dialog v-model="dialogExport" persistent width="640" class="popupdialog">
        <v-card class="d-flex flex-wrap flex-column ">
          <v-card-title class="mb-0 pb-0">
            {{ $t("export") }}
          </v-card-title>
          <v-card-text class="d-flex flex-wrap flex-column">
            <v-row class="d-flex align-center ml-0 mb-3 pa-0">
              <v-col class="d-flex align-center ml-0 pa-0">
                <v-checkbox class="d-flex ml-0 pa-0" v-model="enabled" color="#f99d20" hide-details> <template v-slot:label>
                    <div>
                      {{ $t("exportAll") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <!-- Start Date -->
              <v-col>
                <label style="font-size: 14px">{{ $t("startDate")
                }}<span class="red--text"> *</span></label>
                <!-- <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                  max-width="290px" min-width="290px" :disabled="enabled">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" append-icon="mdi-calendar-month-outline" v-model="computedStartDate"
                      readonly class="kanit-medium pa-0 ma-0" color="#F99D20" persistent-hint v-on="on"
                      style="line-height: 0px" hide-details :disabled="enabled">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title @input="menuStart = false"></v-date-picker>
                </v-menu> -->
                <date-picker v-bind:clearable="false" 
                    format="DD/MM/YYYY" 
                    :style="{ width: '100%' }" readonly 
                    v-model="startDate" 
                    value-type="format"
                    :editable="false" class="custom-vuedatepicker">
                    <!-- <template slot="icon-calendar">
                        <v-icon>
                            mdi-calendar-month-outline
                        </v-icon>
                    </template> -->
                  </date-picker>
              </v-col>
              <!-- End Date -->
              <v-col>
                <label style="font-size: 14px">{{ $t("enddata")
                }}<span class="red--text"> *</span></label>
                <!-- <v-menu v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y fluid
                  max-width="290px" min-width="290px" :disabled="enabled">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" append-icon="mdi-calendar-month-outline" v-model="computedEndDate"
                      :rules="add.endDate" readonly single-line class="kanit-medium pa-0 ma-0" color="#F99D20"
                      persistent-hint v-on="on" style="line-height: 0px" hide-details :disabled="enabled">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title @input="menuEnd = false"></v-date-picker>
                </v-menu> -->
                <date-picker v-bind:clearable="false" 
                    format="DD/MM/YYYY" 
                    :style="{ width: '100%' }" readonly 
                    v-model="endDate" 
                    value-type="format"
                    :disabled-date="allowedEndDates"
                    :error-messages="add.endDate"
                    :editable="false" class="custom-vuedatepicker">
                    <!-- <template slot="icon-calendar">
                        <v-icon>
                            mdi-calendar-month-outline
                        </v-icon>
                    </template> -->
                  </date-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions style="margin-right: 3%" class="pb-6 pt-0">
            <v-spacer></v-spacer>
            <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="dialogExport = false" text>{{
              $t("cancel1") }}</v-btn>
            <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" width="120" max-height="35"
              @click="(Export()) && (dialogExport = false)">{{ $t("export") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- loading dialog -->
      <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/api/Function.js";
import excel from "vue-excel-export";
// import XLSX from "xlsx";
import XLSX from "sheetjs-style";
import DatePicker from "vue2-datepicker";
//import moment from 'moment';
import CustomPaginationComponent from '../components/CustomPagination.vue';
import LoadingDialog from "@/components/Loading.vue";
Vue.use(excel);
export default {
  mixins: [LogTrace],
  components: { DatePicker, CustomPaginationComponent, LoadingDialog, },
  data() {
    return {
      filetype: "Excel (.xlsx)",
      company_Email: null,
      search: "",
      searchCode: "",
      searchName: "",
      searchPkg: null,
      regDateFrom: null,
      regDateTo: null,
      deletesearch: "",
      searchDelCode: "",
      searchDelName: "",
      searchDelPkg: null,
      delDateFrom: null,
      delDateTo: null,
      userid: store.state.username,
      enabled: false,
      loadingdialog: false,
      detaildialog: false,
      companyName: null,
      companyCode: null,
      tele_Number: null,
      contact_Name: null,
      pkgName: null,
      expired_Date: null,
      register_Date: null,
      activeFlag: null,
      company_ID: 0,
      url: enurl.apiUrl,
      totalItems: 0,
      DeltotalItems: 0,
      images: {
        // edit: require("@/assets/images/Mask Group 178.png"),
        // delete: require("@/assets/images/Mask Group 177.png"),
        excel: require("@/assets/images/exccelicon@2x.png"),
      },
      add: {
        endDate: [],
        startDate: [],
      },
      disableloadingforrunning: false,
      runningvalue: 0,
      deletingvalue: 0,
      endDate: null,
      startDate: null,
      menuEnd: false,
      menuStart: false,
      inactivecolor: "text-danger",
      dialogExport: false,
      dialog: false,
      page: 1,
      itemsPerPage: 10,
      removepage: 1,
      removepagecount: 0,
      theAllMight: [],
      theAllForOne: [],
      CompanyList: [],
      ExportList: [],
      Deletecompanylist: [],
      packageList: [],
      status: null,
      deletecompany: {
        companyname: null,
        companycode: null,
        teleph: null,
        email: null,
        country: null,
        city: null,
        address: null,
        expiredate: null,
        userid: null,
      },
      viewicon: require("@/assets/images/reveal icon@2x.png"),
      tab_model: 0,
    };
  },
  computed: {
    imagesbtn() {
      return this.$store.state.images;
    },
    headers() {
      return [
        {
          text: this.$t("companyCode"),
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: this.$t("companyName"),
          value: "companyName",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("register_Date"),
          value: "register_Date",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("expiry_Date"),
          value: "expired_Date",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("Package"),
          value: "pkgName",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("contactname"),
          value: "contact_Name",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("telephone_number"),
          align: "left",
          value: "tele_Number",
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t("email"),
          value: "company_Email",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "status",
          sortable: true,
        },
        {
          text: "Active",
          value: "actions",
          sortable: true,
          align: "left",
        },
      ];
    },
    removeheaders() {
      return [
        {
          text: this.$t("companyCode"),
          align: "left",
          sortable: true,
          value: "companyCode",
        },
        {
          text: this.$t("companyName"),
          value: "companyName",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("register_Date"),
          value: "register_Date",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("expiry_Date"),
          value: "expired_Date",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("Package"),
          value: "pkgName",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("contactname"),
          value: "contact_Name",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("telephone_number"),
          align: "left",
          value: "tele_Number",
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t("email"),
          value: "company_Email",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: true,
        },
        {
          text: "Active",
          value: "removeactions",
          sortable: true,
          align: "left",
        },
      ];
    },
    computedEndDate() {
      return this.formatDate2(this.endDate);
    },
    computedStartDate() {
      return this.formatDate2(this.startDate);
    },
  },

  mounted() {
    this.GetCompanyList();
    this.GetPackage();
    //this.GetDeletecompanylist();
    this.endDate = String(new Date().getFullYear() +
      "-" +
      ((new Date().getMonth() + 1) >= 10 ? (new Date().getMonth() + 1) : ("0" + (new Date().getMonth() + 1))) +
      "-" +
      (new Date().getDate() >= 10 ? new Date().getDate() : ("0" + new Date().getDate())))
    this.startDate = String(new Date().getFullYear() +
      "-" +
      ((new Date().getMonth() + 1) >= 10 ? (new Date().getMonth() + 1) : ("0" + (new Date().getMonth() + 1))) +
      "-" +
      (new Date().getDate() >= 10 ? new Date().getDate() : ("0" + new Date().getDate())))
  },

  watch: {
    endDate() {
      // console.log('ASDF')
      this.add.endDate = [];
    },
    startDate(v) {
      this.add.startDate = [];
      if (new Date(v) > new Date(this.endDate)) {
        this.endDate = null;
      }
    },
    search() {
      this.disableloadingforrunning = true;
      if (this.runningvalue == 1) {
        this.GetCompanyList();
      }
    },
    searchCode() {
      if (this.runningvalue == 1) {
        this.runningvalue = 0;
        this.searchCode = "";
        this.GetCompanyList();
      }
    },
    searchName() {
      if (this.runningvalue == 1) {
        this.runningvalue = 0;
        this.searchName = "";
        this.GetCompanyList();
      }
    },
    searchPkg() {
      if (this.runningvalue == 1) {
        this.runningvalue = 0;
        this.GetCompanyList();
      }
    },
    regDateFrom() {
      if (this.runningvalue == 1) {
        this.runningvalue = 0;
        this.GetCompanyList();
      }
    },
    regDateTo() {
      if (this.runningvalue == 1) {
        this.runningvalue = 0;
        this.GetCompanyList();
      }
    },
    searchDelCode() {
      if (this.deletingvalue == 1) {
        this.deletingvalue = 0;
        this.searchDelCode = "";
        this.GetDeletecompanylist();
      }
    },
    searchDelName() {
      if (this.deletingvalue == 1) {
        this.deletingvalue = 0;
        this.searchDelName = "";
        this.GetDeletecompanylist();
      }
    },
    searchDelPkg() {
      if (this.deletingvalue == 1) {
        this.deletingvalue = 0;
        this.GetDeletecompanylist();
      }
    },
    delDateFrom() {
      if (this.deletingvalue == 1) {
        this.deletingvalue = 0;
        this.GetDeletecompanylist();
      }
    },
    delDateTo() {
      if (this.deletingvalue == 1) {
        this.deletingvalue = 0;
        this.GetDeletecompanylist();
      }
    },
    deletesearch() {
      if (this.deletingvalue == 1) {
        this.GetDeletecompanylist();
      }
    },
  },

  methods: {
    onPageChangedDel(newPage) {
      this.removepage = newPage;
      this.GetDeletecompanylist();
    },

    onItemsPerPageChangedDel(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.removepage = 1;
      this.GetDeletecompanylist();
    },
    onPageChanged(newPage) {
      this.page = newPage;
      this.GetCompanyList();
    },

    onItemsPerPageChanged(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.page = 1;
      this.GetCompanyList();
    },
    orginalOne(v) {
      this.companyCode = null;
      this.companyName = null;
      this.email = null;
      this.company_Email = null;
      this.tele_Number = null;
      this.contact_Name = null;
      this.pkgName = null;
      this.expired_Date = null;
      this.register_Date = null;
      this.search = "";
      this.deletesearch = "";
      this.searchCode = "";
      this.searchName = "";
      this.searchPkg = null;
      this.regDateFrom = null;
      this.regDateTo = null;
      this.searchDelCode = "";
      this.searchDelName = "";
      this.searchDelPkg = null;
      this.delDateFrom = null;
      this.delDateTo = null;
      this.page = 1;
      this.removepage = 1;
      this.itemsPerPage = 10;
      if(v == 1){
        this.GetCompanyList();
      } else {
        this.GetDeletecompanylist();
      }
    },

    allowedEndDates(date) {
      if (!this.startDate) return false;
      const startDate = new Date(this.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(date);
      endDate.setHours(0, 0, 0, 0); 
      return endDate < startDate;
    },

    async Export() {
      let self = this;
      let tempsheetname = "";
      try {
        self.loadingdialog = true;
        if (!self.enabled)
        {
          await axios.get(`${self.url}Company/ExportCompanyManagement?startDate=${self.startDate}&endDate=${self.endDate}`)
            .then(function (response) {
              if (response.data.status == 0) 
              {
                let conlist = response.data.data;
                self.ExportList = conlist.filter((e) => e.company_ID != 0);
              }
              else 
              {
                alert(response.data.message);
              }
            });
          tempsheetname = `Company_Information_${self.startDate}__${self.endDate}`
        }
        else 
        {
          await axios.get(`${self.url}Company/ExportCompanyManagement`)
            .then(function (response) {
              if (response.data.status == 0) 
              {
                let conlist = response.data.data;
                self.ExportList = conlist.filter((e) => e.company_ID != 0);
              }
              else 
              {
                alert(response.data.message);
              }
            });
          tempsheetname = `Company_Information_All_Date`
        }
        /* STEP 1: Create a new workbook */
        let wb = XLSX.utils.book_new();
        /* STEP 2: Create data rows and styles */
        let wsdata = [];
        for (let v of self.ExportList) {
          let reg = self.formatDateExport(v.register_Date)
          let exp = self.formatDateExport(v.expired_Date)
          wsdata.push({
            ["Company Name"]: v.companyName,
            ["Total Employee"]: v.total_Staff,
            ["Active Employee"]: v.active_Staff,
            ["Registraion Date"]: reg,
            ["Expire Date"]: exp,
            ["Contract Name"]: v.contact_Name,
            ["Telephone Number"]: v.tele_Number,
            ["E-mail"]: v.company_Email,
            ["Address"]: v.company_Address,
          });
        }
        /* STEP 3: Create worksheet with rows; Add worksheet to workbook */
        const ws = XLSX.utils.json_to_sheet(wsdata);
        ws["!cols"] = [
          { wch: 15 }, /* Company Name */
          { wch: 13.5 }, /* Total Employee */
          { wch: 13.5 }, /* Active Employee */
          { wch: 14 }, /* Registraion Date */
          { wch: 14 }, /* Expire Date */
          { wch: 15 }, /* Contract Name */
          { wch: 16 }, /* Telephone Number */
          { wch: 30 }, /* E-mail */
          { wch: 60 }, /* Address */
        ];
        var style = { // set the style for target cell                
          font: {
            name: 'Tahoma',
            sz: 10,
            bold: true,
            color: {
              rgb: "000000"
            },
          },
          fill: {
            fgColor: {
              rgb: 'b4c6e7'
            }
          },
        };
        ws["A1"].s = style;
        ws["B1"].s = style;
        ws["C1"].s = style;
        ws["D1"].s = style;
        ws["E1"].s = style;
        ws["F1"].s = style;
        ws["G1"].s = style;
        ws["H1"].s = style;
        ws["I1"].s = style;
        XLSX.utils.book_append_sheet(wb, ws, `${"Data"}`);
        /* STEP 4: Write Excel file to browser */
        XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
          compression: true,
        });
        self.loadingdialog = false;
      } catch (error) {
        alert(error);
      }
    },
    Search() {
      let self = this;
      self.runningvalue = 1;
      self.disableloadingforrunning = false;
      //if (self.search != "" && self.search != undefined) {
      let List = self.CompanyList;
      let temp = List.filter(
        (v) =>
          v.companyCode.toLowerCase().indexOf(self.searchCode.toLowerCase()) >
          -1 ||
          v.companyName.toLowerCase().indexOf(self.searchName.toLowerCase()) >
          -1
        // v.company_Email.toLowerCase().indexOf(self.searchPkg.toLowerCase()) >
        //   -1 ||
        // v.tele_Number.toLowerCase().indexOf(self.search.toLowerCase()) > -1
      );
      self.CompanyList = [];
      for (let i = 0; i < temp.length; i++) {
        self.CompanyList.push(temp[i]);
      }
      //} else if (
      //   (self.search != "" && self.search != undefined) &&
      //   (self.search != "" && self.search != undefined) &&
      //   (self.search != "" && self.search != undefined) &&
      //   (self.search != "" && self.search != undefined) &&
      //   (self.search != "" && self.search != undefined)
      //   )
      //  {
      //   alert("Please fill in search field");
      //   self.GetCompanyList();
      // }
    },

    Search1() {
      let self = this;
      let goon = true;
      let dateis = false;
      if (self.regDateFrom != null) 
      {
        if (self.regDateTo != null) 
        {
          dateis = true;
        } 
        else 
        {
          goon = false;
          alert("Please select both date!")
        }
      }
      if (goon || dateis) {
        self.runningvalue = 1;
        self.GetCompanyList();
        // let List = self.CompanyList;
        // self.CompanyList = [];
        // let List1 = [];
        // for (let i = 0; i < List.length; i++) 
        // {
        //   if (self.searchCode != "" && self.searchName != "" && self.searchPkg != null && dateis == true) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase()) &&
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode != "" && self.searchName != "" && self.searchPkg == null && dateis == true) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase()) &&
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode != "" && self.searchName == "" && self.searchPkg != null && dateis == true) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase()) &&
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode == "" && self.searchName != "" && self.searchPkg != null && dateis == true) 
        //   {
        //     if (
        //       List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase()) &&
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode != "" && self.searchName == "" && self.searchPkg == null && dateis == true) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase()) &&
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode == "" && self.searchName != "" && self.searchPkg == null && dateis == true) 
        //   {
        //     if (
        //       List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase()) &&
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode == "" && self.searchName == "" && self.searchPkg != null && dateis == true) 
        //   {
        //     if (
        //       List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase()) &&
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode != "" && self.searchName != "" && self.searchPkg != null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode != "" && self.searchName != "" && self.searchPkg == null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode != "" && self.searchName == "" && self.searchPkg != null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode == "" && self.searchName != "" && self.searchPkg != null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode != "" && self.searchName == "" && self.searchPkg == null && dateis == false) 
        //   {
        //     if (List[i].companyCode.toLowerCase().includes(self.searchCode.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode == "" && self.searchName != "" && self.searchPkg == null && dateis == false) 
        //   {
        //     if (List[i].companyName.toLowerCase().includes(self.searchName.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode == "" && self.searchName == "" && self.searchPkg != null && dateis == false) 
        //   {
        //     if (List[i].pkgName.toLowerCase().includes(self.searchPkg.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchCode == "" && self.searchName == "" && self.searchPkg == null && dateis == true) 
        //   {
        //     if (
        //       moment(self.regDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.regDateTo)) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   }
        // }
        // self.CompanyList = List1;
        // if (self.searchCode == "" && self.searchName == "" && self.searchPkg == null && dateis == false){
        //   self.CompanyList = self.theAllMight;
        // }
      }
    },
    Search2() {
      let self = this;
      let goon = true;
      let dateis = false;
      if (self.delDateFrom != null) {
        if (self.delDateTo != null) {
          dateis = true;
        } else {
          goon = false;
          alert("Please select both date!")
        }
      }
      if (goon == true || dateis == true) {
        self.deletingvalue = 1;
        self.GetDeletecompanylist();
        // let List = self.Deletecompanylist;
        // self.Deletecompanylist = [];
        // let List1 = [];
        // for (let i = 0; i < List.length; i++) {
        //   if (self.searchDelCode != "" && self.searchDelName != "" && self.searchDelPkg != null && dateis == true) {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase()) &&
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) {
        //       List1.push(List[i]);
        //     }
        //   } else if (self.searchDelCode != "" && self.searchDelName != "" && self.searchDelPkg == null && dateis == true) {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase()) &&
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) {
        //       List1.push(List[i]);
        //     }
        //   } else if (self.searchDelCode != "" && self.searchDelName == "" && self.searchDelPkg != null && dateis == true) {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase()) &&
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) {
        //       List1.push(List[i]);
        //     }
        //   } else if (self.searchDelCode == "" && self.searchDelName != "" && self.searchDelPkg != null && dateis == true) {
        //     if (
        //       List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase()) &&
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) {
        //       List1.push(List[i]);
        //     }
        //   } else if (self.searchDelCode != "" && self.searchDelName == "" && self.searchDelPkg == null && dateis == true) {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase()) &&
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) {
        //       List1.push(List[i]);
        //     }
        //   } else if (self.searchDelCode == "" && self.searchDelName != "" && self.searchDelPkg == null && dateis == true) 
        //   {
        //     if (
        //       List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase()) &&
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode == "" && self.searchDelName == "" && self.searchDelPkg != null && dateis == true) 
        //   {
        //     if (
        //       List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase()) &&
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode != "" && self.searchDelName != "" && self.searchDelPkg != null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase())) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode != "" && self.searchDelName != "" && self.searchDelPkg == null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase()) &&
        //       List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase())) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode != "" && self.searchDelName == "" && self.searchDelPkg != null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase())) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode == "" && self.searchDelName != "" && self.searchDelPkg != null && dateis == false) 
        //   {
        //     if (
        //       List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase()) &&
        //       List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase())) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode != "" && self.searchDelName == "" && self.searchDelPkg == null && dateis == false) 
        //   {
        //     if (List[i].companyCode.toLowerCase().includes(self.searchDelCode.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode == "" && self.searchDelName != "" && self.searchDelPkg == null && dateis == false) 
        //   {
        //     if (List[i].companyName.toLowerCase().includes(self.searchDelName.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode == "" && self.searchDelName == "" && self.searchDelPkg != null && dateis == false) 
        //   {
        //     if (List[i].pkgName.toLowerCase().includes(self.searchDelPkg.toLowerCase())) 
        //     {
        //       List1.push(List[i]);
        //     }
        //   } 
        //   else if (self.searchDelCode == "" && self.searchDelName == "" && self.searchDelPkg == null && dateis == true) 
        //   {
        //     if (
        //       moment(self.delDateFrom) <= moment(List[i].register_Date) &&
        //       moment(List[i].register_Date) <= moment(self.delDateTo)) 
        //       {
        //       List1.push(List[i]);
        //     }
        //   } 
        // }
        // self.Deletecompanylist = List1;
        // if(self.searchDelCode == "" && self.searchDelName == "" && self.searchDelPkg == null && dateis == false){
        //   self.Deletecompanylist = self.theAllForOne;
        // }
      }
    },

    DeleteSearch() {
      let self = this;
      self.deletingvalue = 1;
      if (self.deletesearch != "" && self.deletesearch != undefined) {
        let List = self.Deletecompanylist;
        self.Deletecompanylist = [];

        let temp = List.filter(
          (v) =>
            v.companyCode
              .toLowerCase()
              .indexOf(self.deletesearch.toLowerCase()) > -1 ||
            v.companyName
              .toLowerCase()
              .indexOf(self.deletesearch.toLowerCase()) > -1 ||
            v.company_Email
              .toLowerCase()
              .indexOf(self.deletesearch.toLowerCase()) > -1 ||
            v.tele_Number
              .toLowerCase()
              .indexOf(self.deletesearch.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.Deletecompanylist.push(temp[i]);
        }
      } 
      else 
      {
        alert("Please fill in search field");
        self.GetDeletecompanylist();
      }
    },

    sortFun(rowName) {
      let self = this;
      let keyName = [
        "companyCode",
        "companyName",
        "register_Date",
        "expired_Date",
        "pkgName",
        "contact_Name",
        "status",
        "tele_Number",
        "company_Email",
      ];
      if (
        rowName == "companyCode" ||
        rowName == "companyName" ||
        rowName == "company_Email" ||
        rowName == "pkgName" ||
        rowName == "contact_Name" || 
        rowName == "register_Date" || 
        rowName == "expired_Date"
      ) 
      {
        this.CompanyList = this.CompanyList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      }

      if (rowName == "tele_Number") {
        self.CompanyList = self.CompanyList.sort((a, b) =>
          self[rowName] ? b[rowName] - a[rowName] : a[rowName] - b[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    sortdelete(rowName) {
      let self = this;
      let keyName = [
        "companyCode",
        "companyName",
        "company_Email",
        "tele_Number",
        "register_Date",
        "expired_Date",
        "pkgName",
        "contact_Name",
        "status",
      ];
      if (
        rowName == "companyCode" ||
        rowName == "companyName" ||
        rowName == "company_Email" ||
        rowName == "pkgName" ||
        rowName == "contact_Name" || 
        rowName == "register_Date" || 
        rowName == "expired_Date"
      ) 
      {
        self.Deletecompanylist = self.Deletecompanylist.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      }

      if (rowName == "tele_Number") 
      {
        self.Deletecompanylist = self.Deletecompanylist.sort((a, b) =>
          self[rowName] ? b[rowName] - a[rowName] : a[rowName] - b[rowName]
        );
      }

      if (rowName == "status")
       {
        self.Deletecompanylist = self.Deletecompanylist.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) 
      {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    DeleteDialog(id) {
      this.company_ID = id;
      this.dialog = true;
    },

    goNewCompany(id) {
      this.$router.push({
        name: "EditCompany",
        params: { edit: "edit", id: id },
      }).catch(() => { });
    },

    GetCompanyList() {
      try 
      {
        let self = this;
        if (!self.disableloadingforrunning) 
        {
          self.loadingdialog = true;
        }
        let url = `${self.url}Company/GetCompanyWithPagination?perPage=${self.itemsPerPage}&page=${self.page}`;
          if (self.searchCode != null) 
          {
            url += `&company_Code=${self.searchCode}`;
          }
          if (self.searchName != null) 
          {
            url += `&company_Name=${self.searchName}`;
          }
          if (self.searchPkg != null) 
          {
            url += `&package=${self.searchPkg}`;
          }
          if (self.regDateFrom != null) 
          {
            const date = new Date(self.regDateFrom);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${month}/${day}/${year}`;
            
            url += `&startDate=${formattedDate}`;
          }
          if (self.regDateTo != null) 
          {
            const date = new Date(self.regDateTo);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${month}/${day}/${year}`;
            
            url += `&endDate=${formattedDate}`;
          }
        axios.get(url).then(function (response) {
          if(response.data.status == 0)
          {
            let conlist = response.data.data.companyList;
            self.totalItems =  response.data.data.totalCount;
            self.CompanyList = conlist.filter((e) => e.company_ID != 0);
            self.theAllMight = conlist.filter((e) => e.company_ID != 0);
            //self.LogTrace(null, "Get Company", 12, "Critical");
            self.loadingdialog = false;
            self.disableloadingforrunning = false;
            //self.runningvalue = 0;
            //alert(response.data.message);
          }
          else
          {
            alert(response.data.message);
            self.loadingdialog = false;
          }
        });
      } catch (error) {
        self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.loadingdialog = false;
      }
    },

    DeleteCompany() {
      let self = this;
      self.loadingdialog = true;
      let id = self.company_ID;
      let temp = {
        company_ID: id,
      };
      axios.post(`${self.url}Company/DeleteCompany`, temp)
      .then(function (response) {
        self.loadingdialog = false;
        if (response.data.status == 0)
        {
          self.dialog = false;
          alert(response.data.message);
          self.GetCompanyList();
          self.GetDeletecompanylist();
        }
        else
        {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        self.LogTrace(error, "Delete company Fail", 12, "High");
        alert(error);
      });
    },

    GetDeletecompanylist() {
      try 
      {
        let self = this;
        self.loadingdialog = true;
        let url = `${self.url}Company/GetDeleteCompanyWithPagination?perPage=${self.itemsPerPage}&page=${self.removepage}`;
          if (self.searchDelCode != null) 
          {
            url += `&company_Code=${self.searchDelCode}`;
          }
          if (self.searchDelName != null) 
          {
            url += `&company_Name=${self.searchDelName}`;
          }
          if (self.searchDelPkg != null) 
          {
            url += `&package=${self.searchDelPkg}`;
          }
          if (self.delDateFrom != null) 
          {
            const date = new Date(self.delDateFrom);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${month}/${day}/${year}`;
            url += `&startDate=${formattedDate}`;
          }
          if (self.delDateTo != null) 
          {
            const date = new Date(self.delDateTo);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${month}/${day}/${year}`;
            url += `&endDate=${formattedDate}`;
          }
        axios.get(url)
        .then(function (response) {
          if(response.data.status == 0)
          {
            self.Deletecompanylist = response.data.data.companyList;
            self.theAllForOne = response.data.data.companyList;
            self.DeltotalItems =  response.data.data.totalCount;
            //self.deletingvalue = 0;
            self.loadingdialog = false;
          }
          else
          {
            alert(response.data.message);
            self.loadingdialog = false;
          }
        });
      } 
      catch (error) 
      {
        alert(error);
        self.loadingdialog = false;
      }
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[1] + "/" + finaldate[2] + "/" + finaldate[0];
    },

    formatDate2(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    
    formatDateExport(date) {
      if (!date) return null;
      let monthNames = [
        "Jan", "Feb", "Mar",
        "Apr", "May", "Jun",
        "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"
      ];
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "-" + monthNames[finaldate[1] - 1] + "-" + finaldate[0];
    },

    datestyle(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    detailcompany(temp) {
      let self = this;
      self.detaildialog = true;
      self.deletecompany.companyname = temp.companyName;
      self.deletecompany.companycode = temp.companyCode;
      self.deletecompany.teleph = temp.tele_Number;
      self.deletecompany.email = temp.company_Email;
      self.deletecompany.country = temp.country;
      self.deletecompany.city = temp.city;
      self.deletecompany.address = temp.company_Address;
      self.deletecompany.expiredate = self.formatDate(temp.expired_Date);
      self.deletecompany.userid = temp.username;
    },

    GetPackage() {
      let self = this;
      axios.get(`${self.url}Package_Plan/GetPackagePlan`)
      .then(function (response) {
        if (response.data.status == 0) 
        {
          let tempoArray = response.data.data;
          let theLii = [];
          for (let i = 0; i < tempoArray.length; i++)
          {
            theLii.push(tempoArray[i].pkgName);
          }
          self.packageList = [...new Set(theLii)];
        }
        else 
        {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    BeforeFromDate() {
      let today = new Date();
      let val = new Date(this.regDateFrom);
      let fromdate = this.regDateFrom.getDate();
      if (fromdate == today.getDate()) 
      {
        let d = new Date(val.setDate(val.getDate()));
        return d;
      } 
      else 
      {
        let d = new Date(val.setDate(val.getDate() ));
        return d;
      }
    },

    BeforeFromDelDate() {
      let today = new Date();
      let val = new Date(this.delDateFrom);
      let fromdate = this.delDateFrom.getDate();
      if (fromdate == today.getDate()) 
      {
        let d = new Date(val.setDate(val.getDate()));
        return d;
      } 
      else 
      {
        let d = new Date(val.setDate(val.getDate()));
        return d;
      }
    },
  },
};
</script>

<style scoped>
::v-deep.v-data-table::-webkit-scrollbar {
  width: 6px;
  background-color: none;
  /* You can add other properties & values */
}

::v-deep.v-data-table::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
  /* You can add other properties & values */
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.mytitle {
  font-size: 18px;
  font-weight: 500;
}

.delbtn {
  background-color: #ff6565;
  border-radius: 20px;
}

::v-deep.v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

::v-deep.theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}

::v-deep.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

::v-deep.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

::v-deep.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

::v-deep.v-data-table tr td {
  height: 70px;
}

::v-deep.theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}

::v-deep.theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.firsttab {
  background-color: white;
  width: 140px;
}

.tabone {
  width: 250px;
  border: solid thin #f99d20;
}

::v-deep.mdi-paperclip::before {
  content: none;
}

::v-deep.foricon .mdi:before {
  color: #f99d20;
}

::v-deep.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #f89d1f !important;
  border-radius: 10px 10px 5px 5px !important;
}

::v-deep.v-tabs .v-tab {
  border-radius: 10px 10px 5px 5px !important;
}

::v-deep.v-tabs-slider {
  background-color: #f0f0f7;
  height: 0;
  width: 0;
}

.title {
  font-size: 20px;
}
</style>

<style >
::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f0f0f7;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f0f0f7;
}
.theanoleft{
  padding-left: 0px;
}
.thealign {
  text-align: right;
}
@media (min-width: 280px) and (max-width: 600px) {
  .thewick {
    /* margin-bottom: 30px ; */
    padding-right: 40px;
  }
   .theleft {
    margin-left: 20px;
   }
   .theanoleft {
    padding-left: 20px;
   }
}
@media (max-width: 1304px) {
   .theotherleft {
    margin-left: 20px;
   }
}
@media (max-width: 1263px){
  .thealign {
    text-align: left;
  }
}
</style>