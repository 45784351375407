<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px; margin-top: 22px" class="headertext">
        {{ $t("userManagement") }}
      </p>
    </div>
    <v-card class="dialogmargin card pb-4" style="margin-bottom: 5rem">
      <v-row class="pt-5 d-flex" style="margin-left: 2rem; margin-right: 1rem">
        <v-col cols="12" sm="12" md="3" lg="5" xl="5" class="d-flex justify-start align-center">
            <v-btn
            color="#F99D20"
            class="white--text"
            width="180px"
            router
            :to="{ name: 'addemployee', params: { addemployee: 'addemployee' } }"
            >+ {{ $t("addEmployee") }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="9" lg="7" xl="7" class="d-flex justify-end">
          <v-row class="d-flex justify-end" >
            <v-col class="d-flex justify-end" cols="12" sm="5" md="7" lg="9" xl="7">
              <v-text-field 
                autocomplete="off" v-model="search"
                outlined dense color="#f99d20" 
                :placeholder="$t('searchEmployeeCode')"
                style="margin-left: -1rem;"
                class="searchfield"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end" cols="12" sm="3" md="4" lg="3" xl="2">
              <v-btn
                color="#F99D20"
                @click="Search()"
                class="white--text text-capitalize"
                width="120"
              >
                <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        style="margin-left: 40px; min-height: 200px"
        :headers="headers"
        :items="employeeList"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        hide-default-footer
        hide-default-header
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('employee_Code')"
              >
                {{ headers[0].text }}
                <v-icon small v-if="!employee_Code">mdi-arrow-down</v-icon>
                <v-icon small v-if="employee_Code">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('fullName')"
              >
                {{ headers[1].text }}
                <v-icon small v-if="!fullName">mdi-arrow-down</v-icon>
                <v-icon small v-if="fullName">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('email')"
              >
                {{ headers[2].text }}
                <v-icon small v-if="!email">mdi-arrow-down</v-icon>
                <v-icon small v-if="email">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 180px !important"
                class="b-text"
                @click="sortFun('position')"
              >
                {{ headers[3].text }}
                <v-icon small v-if="!position">mdi-arrow-down</v-icon>
                <v-icon small v-if="position">mdi-arrow-up</v-icon>
              </th>
              <th class="pl-6" style="min-width: 150px !important">{{ $t("action") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.employee_Code }}</td>
            <td>{{ item.fullName }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.position }}</td>

            <td>
              <v-btn icon router @click="EditEmployee(item.employee_ID)">
                <img :src="images.edit" class="editIcon" />
              </v-btn>

              <v-btn @click="Deletedialog(item.employee_ID)" icon>
                <img :src="images.delete" class="deleteIcon"/>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div>
        <CustomPaginationComponent
          :total-items="totalItems"
          :page.sync="page"
          :itemsPerPage.sync="itemsPerPage"
          @update:page="onPageChanged"
          @update:itemsPerPage="onItemsPerPageChanged"
          :showPageText="false"
        />
      </div>
    </v-card>

    <!-- delete  dialog -->
    <v-dialog v-model="dialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("deleteconfirm") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="dialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="Deleteemployee()"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/Function.js";
import CustomPaginationComponent from '../components/CustomPagination.vue';
import LoadingDialog from "@/components/Loading.vue";
export default {
  mixins: [LogTrace],
  components: {
    CustomPaginationComponent, 
    LoadingDialog,
  },
  data() {
    return {
      loadingdialog: false,
      employee_Code: null,
      fullName: null,
      email: null,
      position: null,
      search: "",
      employee_ID: 0,
      url: enurl.apiUrl,
      dialog: false,
      page: 1,
      itemsPerPage: 10,
      seen: true,
      disableloading: false,
      employeeList: [],
      searchlength: 0,
      totalItems: 0,
    };
  },

  async mounted() {
    await this.GetEmployee();
    this.pwd = this.generate();
    this.uid = this.generateUserID();
  },

  computed: {
    images() {
      return this.$store.state.images;
    },
    headers() {
      return [
        {
          text: this.$t("employeeid"),
          align: "left",
          value: "employee_Code",
          sortable: true,
        },
        {
          text: this.$t("fullName"),
          align: "left",
        },
        { text: this.$t("email"), align: "left" },

        { text: this.$t("Position"), align: "left" },
        {
          text: "Active",
          value: "actions",
          sortable: true,
          align: "left",
        },
      ];
    },
  },

  watch: {
    async search() {
      if (this.searchlength == 1) 
      {
        this.searchlength = 0;
        this.search = "";
        await this.GetEmployee();
      }
    },
  },

  methods: {
    onPageChanged(newPage) {
      this.page = newPage;
      this.GetEmployee();
    },

    onItemsPerPageChanged(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.page = 1;
      this.GetEmployee();
    },
    async Search() {
      let self = this;
      if (self.search != "" && self.search != undefined) 
      {
        self.searchlength = 1;
        self.page = 1;
        // self.loadingdialog = true;
        // let List = self.employeeList;
        // self.employeeList = [];
        // let temp = List.filter((v) => v.employee_Code.toLowerCase().includes(self.search.toLowerCase()));
        // for (let i = 0; i < temp.length; i++) 
        // {
        //   self.employeeList.push(temp[i]);
        // }
        // self.loadingdialog = false;
        await self.GetEmployee();
      } 
      else 
      {
        alert("Please fill in search field");
        
      }
    },
    
    sortFun(rowName) {
      let self = this;
      let keyName = ["fullName", "email", "position", "employee_Code"];
      if (rowName == "fullName" || rowName == "email" || rowName == "position") 
      {
        self.employeeList = self.employeeList.sort((a, b) => self[rowName] ? b[rowName].localeCompare(a[rowName]) : a[rowName].localeCompare(b[rowName]));
      } 
      else 
      {
        self.employeeList = self.employeeList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) 
      {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    Deletedialog(id) {
      this.employee_ID = id;
      this.dialog = true;
    },

    EditEmployee(id) {
      this.$router
        .push({
          name: "editemployee",
          params: { editemployee: "editemployee", empid: id },
        })
        .catch(() => {});
    },

    async GetEmployee() {
      try {
        let self = this;
        // if (self.disableloading == false) {
        //   self.loadingdialog = true;
        // }
        // self.disableloading = false;
        self.loadingdialog = true;
        //self.searchlength = 0;
        await axios.get(`${self.url}EmployeeInfo/GetEmployeeInfoPagination?perPage=${self.itemsPerPage}&page=${self.page}&employee_Code=${self.search}`)
          .then(function (response) {
            self.employeeList = response.data.data.result;
            self.totalItems =  response.data.data.totalCount;
            self.loadingdialog = false;
            //self.LogTrace(null, "Successfully", 13, "Critical");
          });
      } 
      catch (error) 
      {
        self.LogTrace(null, "Error", 13, "Critical");
        self.loadingdialog = false;
      }
    },

    async Deleteemployee() {
      let self = this;
      self.loadingdialog = true;
      let id = self.employee_ID;
      let temp = {
        Employee_ID: id,
      };
      await axios.post(`${self.url}EmployeeInfo/DeleteEmployeeInfo`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            //self.LogTrace(null, "Successfully", 13, "High");
            self.GetEmployee();
            self.loadingdialog = false;
            self.dialog = false;
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Error", 13, "High");
          alert(error);
          self.loadingdialog = false;
        });
    },

    generate() {
      let charactersArray = "";
      if (this.characters == undefined) 
      {
        charactersArray = "";
      } 
      else 
      {
        charactersArray = this.characters.split(",");
      }

      let CharacterSet = "";
      let tempPassword = "";
      if (charactersArray.indexOf("a-z") >= 0) 
      {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) 
      {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) 
      {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) 
      {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) 
      {
        tempPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return tempPassword;
    },

    generateUserID() {
      let charactersArray = "";
      if (this.characters == undefined) 
      {
        charactersArray = "";
      } 
      else 
      {
        charactersArray = this.characters.split(",");
      }
      let CharacterSet = "";
      let tempPassword = "";

      if (charactersArray.indexOf("a-z") >= 0) 
      {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) 
      {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      for (let i = 0; i < this.size; i++) 
      {
        tempPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return tempPassword;
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
::v-deep .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
::v-deep .v-text-field__details{
  display: none;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
::v-deep .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}
::v-deep .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
::v-deep .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
::v-deep .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
::v-deep .v-data-table tr td {
  height: 70px;
}
::v-deep .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
::v-deep .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
</style>