import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import enurl from "@/api/environment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: sessionStorage.getItem("token") || "",
    user_ID: parseInt(sessionStorage.getItem("user_ID")) || "",
    usertype: sessionStorage.getItem("usertype") || "",
    username: sessionStorage.getItem("username") || "",
    employee_ID:parseInt(sessionStorage.getItem("employee_ID"))|| "",
    company_ID:parseInt(sessionStorage.getItem("company_ID"))|| "",
    noti : sessionStorage.getItem("noti"),
    user: {},
    images: {
      edit: require("@/assets/images/editIcon.svg"),
      delete: require("@/assets/images/deleteIcon.svg"),
    }
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, user) {
      state.status = "success";
      state.token = user.token;
      state.user_ID = user.user_ID;
      state.usertype = user.userType;
      state.username = user.username;
      state.employee_ID=user.employee_ID;
      state.company_ID=user.company_ID;

      // eslint-disable-next-line no-console
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user_ID = "";
      state.usertype = "";
      state.employee_ID="";
      state.company_ID="";
    }
  },
  actions: {
    loginAdmin({
      commit,
    }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
            url: `${enurl.apiUrl}Login/LoginAdmin`,
            data: user,
            method: "POST"
          })
          .then(resp => {


            if (resp.data.status != 0) {
              commit("auth_error");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("user_ID");
              sessionStorage.removeItem("usertype");
              sessionStorage.removeItem("username");
              sessionStorage.removeItem("employee_ID");
              sessionStorage.removeItem("company_ID");
        
              reject(resp.data.message);
            } else {
              const token = resp.data.data.token;
              const user_ID = resp.data.data.user_ID;
              const userType = resp.data.data.userType;
              const user = resp.data.data;
              const userName = resp.data.data.username;
              const employee_ID=resp.data.data.employee_ID;
              const company_ID=resp.data.data.company_ID;
        
              sessionStorage.setItem("token", token);
              sessionStorage.setItem("user_ID", user_ID);
              sessionStorage.setItem("usertype", userType);
              sessionStorage.setItem("username", userName);
              sessionStorage.setItem("employee_ID",employee_ID);
              sessionStorage.setItem("company_ID",company_ID);
       

              // Add the following line:
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + token;
              commit("auth_success", user);
              resolve(resp);
            }
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },

  

    logout({
      commit
    }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        commit("logout");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user_ID");
        sessionStorage.removeItem("usertype");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("employee_ID");
        sessionStorage.removeItem("company_ID");
  
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    usertype: state => state.usertype,
    user_ID: state => state.user_ID,
    company_ID: (state) => state.company_ID,
    employee_ID: (state) => state.employee_ID,
 
  }
});