<template>
  <div v-show="remembermeAd == 'false'" class="full-height" style="background-color:#fff2e3">
    <v-container fluid fill-height>
      <v-row class="align-center justify-center">
        <v-col cols="12" md="6" lg="6" xl="6" class="image-column hidden-sm-and-down ma-0 pa-0">
          <v-img class="image" src="@/assets/HomeImage.png" :height="'100%'" :width="'100%'"></v-img>
        </v-col>
        
        <v-col cols="12" md="6" lg="6" xl="6" class="text-column">
          <div class="right-text-container">
            <v-container fluid>
              <!-- Language Selector Row -->
              <v-row class="language-selector">
                <v-col cols="12" align="right">
                  <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn text dark v-on="on" @click="changeLang">
                        <country-flag :country="currentflag" size="normal" />
                        <span class="text-capitalize lang-title hidden-sm-and-down">&nbsp;&nbsp;{{ currentLangTitle }}</span>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group v-model="lang" color="#F89D1F">
                        <v-list-item v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language,entry.flag,entry.title)">
                          <v-list-item-action>
                            <country-flag :country="entry.flag" size="normal" />
                          </v-list-item-action>
                          <v-list-item-title>{{ entry.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>

              <!-- Header Text -->
              <v-row justify="center">
                <v-col cols="12">
                  <p class="text-center welcomeToHeader">Welcome to</p>
                  <p class="text-center optimisticHeader">OPTIMISTIC</p>
                </v-col>
              </v-row>

              <!-- Alerts -->
              <v-row justify="center">
                <v-col cols="12" md="8" lg="8" xl="6">
                  <v-alert style="text-align: center" type="error" v-model="erroralert">
                    {{ errorMessage == "You have failed logging in for 5 times already.Please click on Forgot Password to reset and try again" ? $t("YouHaveFailed") : $t("InvalidUsernameAndPassword") }}
                  </v-alert>
                  <v-alert v-if="remembermeAd" type="info" style="text-align: center" v-model="successalert">
                    {{ successMessage }}
                  </v-alert>
                </v-col>
              </v-row>

              <!-- Username and Password Fields -->
              <v-row justify="center">
                <v-col cols="12" md="8" lg="8" xl="6">
                  <v-text-field color="warning" style="margin-bottom: 16px;" class="textFieldCustom" :label="$t('username')" v-model="input.username" dense v-on:keydown.enter="login()">
                    <template v-slot:prepend-inner>
                      <img width="24" height="24" src="@/assets/icons/Mask Group 140.svg" alt />
                    </template>
                  </v-text-field>
                  <v-text-field color="warning" :label="$t('password')" class="textFieldCustom" v-model="input.password" :type="show1 ? 'text' : 'password'" dense v-on:keydown.enter="login()">
                    <template v-slot:prepend-inner>
                      <img width="24" height="24" src="@/assets/icons/Mask Group 141.svg" alt />
                    </template>
                    <template v-slot:append>
                      <v-btn icon color="white">
                        <img width="40" height="40" @click="show1 = !show1" :src="show1 ? pwdimage.showpwd : pwdimage.hidepwd" alt />
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Remember Me Checkbox -->
              <v-row justify="center">
                <v-col cols="12" md="8" lg="8" xl="6">
                  <v-checkbox :label="$t('rememberMe')" v-model="rememberMe"></v-checkbox>
                </v-col>
              </v-row>

              <!-- Login Button -->
              <v-row justify="center">
                <v-col cols="12" sm="12" xs="12" style="flex-basis: 428px; align-items: center">
                  <v-btn rounded color="#F99D20" block @click="login()" :loading="loading" style="height: 52px; font-size: 17px; color: white" depressed class="text-capitalize">
                    {{ $t("login") }}
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Forgot Password Link -->
              <v-row justify="center">
                <v-col cols="12" md="8" lg="8" xl="6" align="center">
                  <a style="color: #0fa7d8;font-size: 17px" @click="gotoforget()">{{ $t("forgetpsw") }}</a>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>

      <LoadingDialog :LoadingDialog="dialog"></LoadingDialog>
    </v-container>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import CountryFlag from "vue-country-flag";
import enurl from "@/api/environment";
import axios from "axios";
import i18n from "@/translate/i18n";
import LoadingDialog from "@/components/Loading.vue";
export default {
  components: { CountryFlag, LoadingDialog },
  data() {
    return {
      secretKey: "OptimisticSecretPassphrase@2020",
      rememberMe: false,
      expiryDate: "",
      remembermeAd: "false",
      checkuser: null,
      currentflag: "us",
      currentLangTitle: "English",
      lang: 1,
      url: enurl.apiUrl,
      checkuserCount: 1,
      languages: [
        { flag: "th", language: "th", title: "ประเทศไทย" },
        { flag: "us", language: "en", title: "English" },
        { flag: "CN", language: "cn", title: "中文" },
        { flag: "VN", language: "vn", title: "Tiếng Việt" },
        { flag: "MM", language: "mm", title: "မြန်မာဘာသာ" },
      ],
      is_Locked: false,

      customImgHeight: "100%",
      input: {
        username: "",
        password: "",
      },
      pwdimage: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png"),
      },
      erroralert: false,
      successalert: false,
      successMessage: "",
      errorMessage: "",
      loading: false,
      dialog: false,
      show1: false,

      userList: [],
    };
  },
  created() {
    let self = this;
    let login_username = self.readCookie("username");
    let login_password = self.readCookie("password");
    self.remembermeAd = self.readCookie("remembermeAd");

    if (self.remembermeAd == "true") {
      self.expiryDate = self.readCookie("expiredate");
      let today = new Date();
      if (today >= self.expiryDate) {
        document.cookie = "username=" + ";expires=" + self.expiryDate;
        document.cookie = "password=" + ";expires=" + self.expiryDate;
        document.cookie =
          "remembermeAd='false'" + ";expires=" + self.expiryDate;
      } else {
        if (login_username != null && login_password != null) {
          self.input.username = self.cryptoMethods("decrypt", login_username);
          self.input.password = self.cryptoMethods("decrypt", login_password);
          // self.remembermeAd;
          self.login();
        }
      }
    } else {
      self.remembermeAd = "false";
      self.expiryDate = new Date();
      self.expiryDate.setMonth(self.expiryDate.getMonth() + 1);
      document.cookie = `expiredate=${self.expiryDate};expires=${self.expiryDate}`;
    }
  },
  mounted() {
    if (sessionStorage.locale == null) {
      i18n.locale = "en";
    } else {
      i18n.locale = sessionStorage.getItem("locale");
    }

    if (sessionStorage.flag == null) {
      this.currentflag = "us";
    } else {
      this.currentflag = sessionStorage.getItem("flag");
    }

    if (sessionStorage.title == null) {
      this.currentLangTitle = "English";
    } else {
      this.currentLangTitle = sessionStorage.getItem("title");
    }
  },
  methods: {
    cryptoMethods(type, value) {
      if (value != null || value != "" || value != undefined) {
        if (type == "encrypt") {
          const encryptedText = CryptoJS.AES.encrypt(
            value,
            this.secretKey
          ).toString();
          return encryptedText;
        } else {
          const decryptedText = CryptoJS.AES.decrypt(
            value,
            this.secretKey
          ).toString(CryptoJS.enc.Utf8);
          return decryptedText;
        }
      }
    },
    readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    changeLang() {
      if (sessionStorage.locale == "th")
      {
        this.lang = 0;
      }
      else if (sessionStorage.locale == "us" || sessionStorage.locale == null)
      {
        this.lang = 1;
      }
      else if (sessionStorage.locale == "cn")
      {
        this.lang = 2;
      }
      else if (sessionStorage.locale == "vn")
      {
        this.lang = 3;
      }
      else if (sessionStorage.locale == "mm")
      {
        this.lang = 4;
      }
    },

    changeLocale(locale, flag, title) {
      this.currentflag = flag;
      i18n.locale = locale;
      this.currentLangTitle = title;
      document.cookie = "lang=" + locale;
      document.cookie = "flag=" + flag;
      document.cookie = "title=" + title;
      sessionStorage.setItem("locale", locale);
      sessionStorage.setItem("flag", flag);
      sessionStorage.setItem("title", title);
    },

    login: function () {
      let self = this;
      self.is_Locked = false;
      self.clearMessage();
      self.loading = true;
      let userName = self.input.username.toLowerCase();
      let password = self.input.password;
      self.getLock();
      if(
        self.input.username != null &&
        self.input.username != "" &&
        self.input.password != null &&
        self.input.password != ""
      )
      {
        if (self.is_Locked == true)
        {
          self.loading = false;
          self.errorMessage = false;
        }
        if (self.is_Locked == false)
        {
          self.$store
            .dispatch("loginAdmin", { userName, password })
            .then(function (response) {
              self.successalert = true;
              if (response.data.message == "Successfully")
              {
                //console.log(response.data.message)
                self.successMessage = self.$t("successlogin");
                document.cookie =
                  "username=" +
                  self.cryptoMethods("encrypt", userName) +
                  ";expires=" +
                  self.expiryDate;
                document.cookie =
                  "password=" +
                  self.cryptoMethods("encrypt", password) +
                  ";expires=" +
                  self.expiryDate;
                let tempRem =
                  self.rememberMe || self.remembermeAd == "true"
                    ? "true"
                    : "false";
                document.cookie =
                  "remembermeAd=" + tempRem + ";expires=" + self.expiryDate;
                self.loading = false;
                self.model = { username: "", password: "" };
                setTimeout(() => {
                  self.$router.push({ name: "CompanyManagement" }).catch(()=>{});
                }, 1000);
              }
            })
            .catch(function () {
              if (self.checkuserCount == 4) {
                let temp = {
                  username: userName,
                };
                axios
                  .post(`${self.url}UserInfo/LockAccount`, temp)
                  .then(function (response) {
                    self.erroralert = true;
                    self.errorMessage = response.data.message;
                    self.loading = false;
                  });
              } else {
                self.erroralert = true;
                if (self.checkuser == self.input.username) {
                  self.checkuserCount++;
                } else {
                  self.checkuserCount = 1;
                }
                if (self.is_Locked == true) {
                  self.erroralert = true;
                  self.errorMessage =
                    "You have failed logging in for 5 times already.Please click on Forgot Password to reset and try again";

                  self.loading = false;
                } else {
                  self.errorMessage = "Invalid Username and Password";
                }
                self.checkuser = self.input.username;
              }
              self.loading = false;
            });
        }
      } else {
        alert("Please fill in the required fields!");
        self.loading = false;
      }
    },
    clearMessage: function () {
      this.erroralert = false;
      this.successalert = false;
      this.errorMessage = "";
      this.successMessage = "";
    },
    gotoforget() {
      this.$router.push({ name: "forgetpassword" }).catch(()=>{});
    },
    getLock() {
      let self = this;
      if (self.input.username != null) {
        let userName = self.input.username.toLowerCase();
        let tempLock = {
          username: userName,
        };
        axios
          .post(`${self.url}UserInfo/GetUserbyCompanyName`, tempLock)
          .then(function (response) {
            let temp = response.data.data;
            self.is_Locked = temp[0].is_Locked;
          });
      }
    },
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-column {
  max-height: 100vh;
  overflow: hidden;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@media (max-width: 428px) {
  .welcomeToHeader, .optimisticHeader {
    font-size: 2rem;
  }
}

/* Default styling for .text-column */
.text-column {
  padding: 1rem;
}

/* For screens between 428px and 960px */
@media (max-width: 960px) and (min-width: 429px) {
  .text-column {
    padding: 1rem; /* Already set in default, no need to repeat */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* For screens smaller than 428px */
@media (max-width: 428px) {
  .text-column {
    padding: 2rem;
  }
}

.language-selector {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
}

.welcomeToHeader {
  color: #0fa7d8;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  text-shadow: 0px 3px 6px #00000029;
  font-weight: bold;
}

.optimisticHeader {
  font-size: 60px;
  color: #f99d20;
  font-family: "Montserrat", sans-serif;
  margin-top: -30px;
  font-weight: bold;
}

.textFieldCustom {
  width: 500px;
}

.textFieldCustom .v-text-field__slot {
  padding-bottom: 5px;
}

.lang-title {
  color: #f89d1f;
  font-size: 16px;
}
</style>