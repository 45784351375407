<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        style="margin-left: -1rem"
        dark
        v-on="on"
        @click="changenoti()"
      >
        <v-badge
          :content="notiCount"
          :value="notiCount"
          color="red"
          overlap
          style="margin-bottom: 1.5rem; margin-left: 1.5rem; position: absolute"
          v-if="actualNotiState"
        >
        </v-badge>
        <v-icon color="#F89D1F">mdi-bell</v-icon>
      </v-btn>
    </template>

    <v-list v-show="requestList.length > 0">
      <div class="notiHeading">
        <v-list-item-group class="ma-0 white">
          <v-row class="mx-2 my-2">
            <v-col cols="6">
              <div style="color: #f89d1f">{{ $t("notifications") }}</div>
            </v-col>
            <v-col cols="6">
              <!-- <div class="btnForMarkAllAsRead">Mark all as read</div> -->
            </v-col>
          </v-row>
          <v-list-item
            v-for="(item, i) in requestList"
            :key="i"
            @click="
              sendDataWithParams(
                item.wF_ID,
                item.feedback_ID,
                item.notificationID,
                i,
                item.seen,
                item
              )
            "
            style="margin-top: 1px"
            v-bind:style="{ background: colorList[i] }"
          >
            <img
              :src="item.senderImage"
              v-if="item.senderImage"
              style="
                border: 1px solid #43425d;
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 25px;
              "
            />
            <img
              src="@/assets/profile.svg"
              v-if="!item.senderImage"
              style="
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 25px;
              "
            />
            <v-list-item-title style="background:item.seen?white:#FAF5F4">
              <div>
                <div class="notiText" style="margin-bottom: 2px">
                  {{ item.senderName }}
                  {{ Checkingtranslate(item.description) }}
                </div>
                <div class="notiText">
                  {{ convertUTCDateToLocalDate(item.noti_date) }}
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-row class="ma-0 white" style="align-items: center">
          <v-col cols="12">
            <div class="notiButton">
              <v-btn
                small
                height="30"
                width="100%"
                color="#F99d20"
                route
                to="/Notification"
              >
                {{ $t("SeeAll") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </v-list>
    
    <v-list v-show="!requestList.length > 0">
      <div class="notiHeading" style="align-items: center; text-align: center; color: #f99d20">
        <p>{{ $t("NoNotifications") }}</p>
      </div>
    </v-list>

    <!-- loading dialog -->
  <v-dialog v-model="loadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-menu>
</template>
<script>
import axios from "axios";
import store from "@/store";
import enurl from "@/api/environment";
import firebase from "firebase/app";

export default {
  data: () => ({
    url: enurl.apiUrl,
    requestList: [],
    mixins: JSON.parse(store.state.noti),
    profileimage: store.state.profileimage,
    token: null,
    reportID: null,
    tokenList: [],
    noti: false,
    unseenList: 0,
    colorList: [],
    notiCount: 0,
    firstTime: 0,
    actualNotiState: false,
    loadingdialog: false,
    showNoti: false
  }),
  created() {
    this.noti = JSON.parse(store.state.noti);
    this.noti = JSON.parse(localStorage.getItem("firebasenotification"));
  },

  async mounted() {
    let self = this;
    await self.generateToken();
    await self.GetrequestList();
    await self.sendNotification();
    let checkimage = store.state.profileimage;
    if (checkimage == "null") {
      self.profileimage = "@/assets/profile.svg";
    }
  },

  watch: {
    SearchReportTo(e) {
      this.noti = e;
    },
  },
  computed: {
    Checkingtranslate() {
      return function (value) {
        return value == "has sent a feedback"
          ? this.$t("Sentfeedback")
          : value == "has replied to a feedback"
          ? this.$t("Repliedfeedback")
          : this.$t("Replyyourfeedback");
      };
    },
  },
  methods: {
    formatDate(date) {
      // let finaldate = date.slice(0, 10).split("-");
      let spliteDateAndTime = date.split("T");
      let Date = spliteDateAndTime[0].split("-");
      let time = spliteDateAndTime[1].split(":");
      return (
        Date[0] + "-" + Date[1] + "-" + Date[2] + " " + time[0] + ":" + time[1]
      );
    },

    convertUTCDateToLocalDate(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = null;
      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } 
      else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10)
      {
        output = newDate.getDate() + "/" +(newDate.getMonth() + 1) +"/" +newDate.getFullYear() + " " + newDate.getHours() + ":" + "0" + newDate.getMinutes();
      } 
      else 
      {
        output = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear() + " " + newDate.getHours() + ":" + newDate.getMinutes();
      }
      return output;
    },

    async GetrequestList() {
      let self = this;
      self.requestList = [];
      self.notiCount = 0;
      self.unseenList = 0;
      self.colorList = [];
      await axios.get(`${self.url}NotificationInfo/GetLatestNotiProductAdmin`)
        .then(function (response) {
          let temp = response.data.data;
          temp
            .slice()
            .reverse()
            .forEach(function (item) {
              if (self.requestList.length < 10) 
              {
                self.requestList.length = 0;
                self.requestList.push(item);
                if (item.seen == false) 
                {
                  if (self.unseenList <= 10) 
                  {
                    self.unseenList++;
                  }
                  self.colorList.push("#D0D0D0");
                } 
                else 
                {
                  self.colorList.push("white");
                }
              }
            });
        });

      if (self.unseenList > 0) 
      {
        self.notiCount = self.unseenList;
      }
      this.actualNotiState = true;
    },

    async changenoti() {
      let self = this;
      self.notiCount = 0;
      self.loadingdialog = true;
      if (self.requestList) 
      {
        await self.GetrequestListForNotiOff();
        self.loadingdialog = false;
      }
    },

    async GetrequestListForNotiOff() {
      let self = this;
      self.requestList = [];
      self.colorList = [];
      await axios.get(`${self.url}NotificationInfo/GetLatestNotiProductAdmin`)
        .then(function (response) {
          let temp = response.data.data;
          self.showNoti = true;
          temp
            .slice()
            .reverse()
            .forEach(function (item) {
              if (self.requestList.length < 10) 
              {
                self.requestList.push(item);
                if (item.seen == false) 
                {
                  if (self.unseenList <= 10) 
                  {
                    self.unseenList++;
                  }
                  self.colorList.push("#D0D0D0");
                } 
                else 
                {
                  self.colorList.push("white");
                }
              }
            });
        });
      //this.actualNotiState = false;
    },

    changeState(index, status) {
      if (status == false) 
      {
        if (this.unseenList >= 1) 
        {
          this.unseenList--;
          this.colorList.push("white");
          this.colorList[index] = "white";
        }
      }
    },

    async sendDataWithParams(
      input,
      Feedback,
      notificationID,
      index,
      status,
      item
    ) {
      this.changeState(index, status);
      let temp = {
        notificationID: notificationID,
      };
      if (Feedback == 0) 
      {
        await axios.post(`${this.url}NotificationInfo/EditnotificationState`, temp)
          .then(function () {});
        if (item.wF_Name === "Feedback") 
        {
          this.$router.push({
            name: "HelpAndFeedbackNotification",
            params: {
              notiString: input,
              condition:
                item.description === "has sent a feedback" ? "Sent" : "Replied",
            },
          }).catch(()=>{});
        }
      } 
      else 
      {
        await axios.post(`${this.url}NotificationInfo/EditnotificationState`, temp)
          .then(function () {});
        if (item.wF_Name === "Feedback") 
        {
          this.$router.push({
            name: "HelpAndFeedbackNotification",
            params: {
              notiString: Feedback,
              condition: (item.description = "Replied"),
            },
          }).catch(()=>{});
        }
      }
    },

    updatePathParams($router, newParams) {
      // Retrieve current params
      const currentParams = $router.currentRoute.params;

      // Create new params object
      const mergedParams = { ...currentParams, newParams };

      // When router is not supplied path or name,
      // it simply tries to update current route with new params or query
      // Almost everything is optional.
      $router.push({ params: mergedParams }).catch(()=>{});
    },

    async sendNotification() {
      let id = sessionStorage.employee_ID;
      let temp = {
        user_ID: parseInt(id),
      };
      let token = [];
      await axios.post(`${this.url}Firebasetoken/GetFirebaseTokenByUserID`, temp)
        .then(function (response) {
          var temp = response.data.data;
          if (temp[0]) 
          {
            temp.map((t) => token.push(t.fireBase_Token));
          }
        });
      return token;
    },

    async generateToken() {
      let self = this;
      var tempTokenList = await self.sendNotification();
      var firebaseConfig = {
        apiKey: "AIzaSyAI4HkLNx1R3atL7BxB7qwvEKQqjiY8k4Y",
        authDomain: "optimistic-cca96.firebaseapp.com",
        databaseURL: "https://optimistic-cca96.firebaseio.com",
        projectId: "optimistic-cca96",
        storageBucket: "optimistic-cca96.appspot.com",
        messagingSenderId: "116479546535",
        appId: "1:116479546535:web:dc597974122619205eb782",
        measurementId: "G-DWP25MZ4LD",
      };
      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
      }
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then((token) => {
          //console.log(token);
          self.AddFirebaseToken(token, tempTokenList);
        })
        .catch(function (err) {
          alert(err);
        });

      messaging.onMessage((payload) => {
        // alert("payload" + JSON.stringify(payload));
        //console.log(JSON.stringify(payload));
        if (payload) 
        {
          //await alert(payload.notification.body);
          if (parseInt(store.state.employee_ID) == parseInt(payload.notification.icon)) 
          {
            self.notiCount = 0;
            self.unseenList = 0;
            if (self.unseenList > 0) 
            {
              self.notiCount = self.unseenList;
              self.actualNotiState = true;
            }
            self.GetrequestList();
          }
        }
      });
    },

    async AddFirebaseToken(token, tempTokenList) {
      let n = 0;
      await tempTokenList.forEach((element) => {
        if (element == token) 
        {
          n = n + 1;
        }
      });
      if (n == 0) 
      {
        let tempLock = {
          user_ID: store.state.employee_ID,
          fireBase_Token: token,
        };
        axios.post(`${this.url}Firebasetoken/AddFirebaseToken`, tempLock)
          .then(function () {
            // console.log("aaa" + response);
          });
      }
    },
  },
};
</script>
<style scoped>
.notiText {
  color: #444444;
  font-size: 12px;
  font-family: Kanit, sans-serif;
}

.notiHeading {
  width: 500px;
  height: auto;
  max-height: 500px;
  background: white;
}

.btnForMarkAllAsRead {
  margin-left: 20px;
  color: black;
  font-size: 12px;
  margin-top: 2px;
}

.notiButton {
  width: 250px;
  margin-left: 130px;
}

@media (max-width: 330px) and (max-height: 570px) {
  .notiButton {
    width: 220px;
    margin-left: 10px;
  }
}

@media (max-width: 330px) and (max-height: 570px) {
  .notiHeading {
    width: 260px;

    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}

@media (max-width: 380px) and (max-height: 680px) {
  .notiHeading {
    width: 260px;
    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}

@media (max-width: 415px) and (max-height: 740px) {
  .notiHeading {
    width: 260px;
    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}

@media (max-width: 380px) and (max-height: 820px) {
  .notiHeading {
    width: 260px;
    margin-left: 10px;
    font-size: 10px;
  }
  .notiText {
    font-size: 7px;
  }
  .btnForMarkAllAsRead {
    margin-left: 20px;
    color: black;
    font-size: 10px;
    margin-top: 2px;
  }
  .notiButton {
    width: 200px;
    margin-left: 10px;
  }
}
</style>