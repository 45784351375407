<template>
    <div class="text-right">
      <v-row class="ml-5 mt-5 mr-5">
        <v-col align="left" cols="12" md="4">
          <p v-show="showPageText">
            {{ $t("page") }} {{ localPage }} {{ $t("of") }} {{ pageCount }} {{ $t("pages") }}, 
            {{ totalItems }} {{ $t("dataitems") }}
          </p>
        </v-col>
        <v-spacer ></v-spacer>
        <v-col cols="12" md="3" lg="4">
          <v-pagination
            v-model="localPage"
            :length="pageCount"
            color="#F9A531"
            :total-visible="6"
            @input="emitPageChange">
          </v-pagination>
        </v-col>
        <v-col cols="12" md="2" class="mt-1">
          <v-select
            dense
            style="width: 120px; float: right"
            solo
            :label="`${localItemsPerPage}/page`"
            v-model="localItemsPerPage"
            :items="perPageOptions"
            @input="emitItemsPerPageChange">
          </v-select>
        </v-col>
      </v-row>
    </div>
</template>
  
<script>
  export default {
    props: {
      totalItems: {
        type: Number,
        required: true,
      },
      itemsPerPage: {
        type: Number,
        default: 10,
      },
      page: {
        type: Number,
        default: 1,
      },
      showPageText: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        localPage: this.page, // Initialize with the value of prop page
        localItemsPerPage: this.itemsPerPage, // Initialize with the value of itemsPerPage prop
      };
    },
    watch: {
      // Watch prop changes and update local values accordingly
      page(newPage) {
        this.localPage = newPage;
      },
      itemsPerPage(newItemsPerPage) {
        this.localItemsPerPage = newItemsPerPage;
      },
    },
    computed: {
      pageCount() {
        return Math.ceil(this.totalItems / this.localItemsPerPage);
      },
      perPageOptions() {
        return [10, 20, 30, 50];
      },
    },
    methods: {
      emitPageChange() {
        this.$emit('update:page', this.localPage); // Emit the new page value to the parent
      },
      emitItemsPerPageChange() {
        this.$emit('update:itemsPerPage', this.localItemsPerPage); // Emit the new itemsPerPage value to the parent
      },
    },
  };
</script>
  