<template>
  <div class="hello" style="padding: 0px; min-height: 100vh">
    <v-container>
      <v-row>
        <v-col cols="12" align="center" class="mt-3">
          <div class="mt-10">
            <img src="@/assets/images/logo.png" alt />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center">
          <p class="text-center welcomeToHeader">Reset Your Password</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center">
          <p class="text-center createPass">Please create new password.</p>
        </v-col>
      </v-row>

      <v-row justify="center" no-gutters class="ml-3">
        <v-col cols="12" xs="4" sm="5" md="4" lg="3" xl="2">
          <v-text-field
            :error-messages="error_New_Password"
            v-model="new_Password"
            color="warning"
            :type="show1 ? 'text' : 'password'"
          >
            <template v-slot:prepend-inner>
              <img
                width="24"
                height="24"
                alt
                src="@/assets/images/Mask Group 141.png"
              />
            </template>
            <template v-slot:append>
              <v-btn icon color="white">
                <img
                  width="40"
                  height="40"
                  @click="show1 = !show1"
                  :src="show1 ? pwdimage.showpwd : pwdimage.hidepwd"
                  alt
                />
              </v-btn>
            </template>
            <template v-slot:label>
              <span
                >
                New password<span class="red--text"> *</span></span
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters class="ml-3">
        <v-col cols="12" xs="4" sm="5" md="4" lg="3" xl="2">
          <v-text-field
            :error-messages="error_Confirm_Password"
            v-model="confirm_Password"
            color="warning"
            :type="show2 ? 'text' : 'password'"
          >
            <template v-slot:prepend-inner>
              <img
                width="24"
                height="24"
                alt
                src="@/assets/images/Mask Group 141.png"
              />
            </template>
            <template v-slot:append>
              <v-btn icon color="white">
                <img
                  width="40"
                  height="40"
                  @click="show2 = !show2"
                  :src="show2 ? pwdimage.showpwd : pwdimage.hidepwd"
                  alt
                />
              </v-btn>
            </template>
            <template v-slot:label>
              <span
                >
                Confirm password<span class="red--text"> *</span></span
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="pl-3 mt-2" no-gutters>
        <v-col cols="12" xs="8" sm="5" md="4" lg="3" xl="2">
          <v-btn
            rounded
            color="#F99D20"
            block
            style="height: 52px; font-size: 17px; color: white"
            depressed
            class="text-capitalize kanit"
            @click="Newpassword"
            >Done</v-btn
          >
        </v-col>
      </v-row>
      <br />
      <br />
      <br />
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/Function.js";
export default {
   mixins: [LogTrace],
  data() {
    return {
      new_Password: "",
      confirm_Password: "",
      error_New_Password: "",
      error_Confirm_Password: "",
      show1: false,
      show2: false,
      url: enurl.apiUrl,
      user_ID: 0,
      user_Name: "",
      pwdimage: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png"),
      },
    };
  },
  // mounted() {

  // },
  watch: {
    "new_Password"(){
      this.error_New_Password = [];
    },
    "confirm_Password"(){
      this.error_Confirm_Password = [];
    },
  },
  methods: {
    Newpassword() {
      let self = this;
      self.error_New_Password = !self.new_Password
        ? "New password is required"
        : "";
      self.error_Confirm_Password = !self.confirm_Password
        ? "Confirm password is required"
        : "";
      let tempvali = self.validateTrue();

      if (tempvali == true) {
        let params = self.$route.params.Resetid;
        let getId = params.split("&");
        let getName = getId[3];
        let Name = getName.split("=");
        self.user_Name = Name[1];
        let gettingId = getId[1];
        let id = gettingId.split("=");
        self.user_ID = id[1];
        let temp = {
          user_ID: parseInt(self.user_ID),
          password: self.confirm_Password,
          username: self.user_Name,
        };
        axios
          .post(`${self.url}UserInfo/UpdateAdmin`, temp)
          .then(function (response) {
            alert(response.data.message);
            self.LogTrace(null, "Reset Password", 54, "Low");
            self.confirm_Password = null;
            self.new_Password = null;
            self.$router.push({ name: "Login" }).catch(()=>{});
          })
          .catch(function(error){
            alert(error);
             self.LogTrace(error, "Reset Password Fail", 54, "Low");
          })
      } else {
        self.ValidateFalse();
      }
    },
    validateTrue() {
      let self = this;
      let newPassword = self.chekText(self.new_Password, self.confirm_Password);
      if (
        self.new_Password != null &&
        self.new_Password != "" &&
        self.confirm_Password != null &&
        self.confirm_Password != "" &&
        newPassword == true
      ) {
        return true;
      } else {
        return false;
      }
    },
    chekText(newPass, confirm) {
      if (newPass.length === confirm.length && newPass === confirm) {
        return true;
      } else {
        alert(
          "Your new password and confirm password does not match. Please check "
        );
      }
    },
    ValidateFalse() {
      let self = this;
      if (
        self.error_New_Password == null ||
        self.error_Confirm_Password == null
      ) {
        alert("Please fill in the required fields!");
      }
    },
  },
};
</script>

<style scoped>
.welcomeToHeader {
  color: #f99d20;
  font-size: 22px;
  font-family: Kanit, "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.createPass {
  color: #3c4043;
  font-size: 18px;
  font-family: Kanit, "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.hello {
  background-color: #fff2e3;
}

/* @media (max-width: 414px) {
.hello-right{
  height: 103vh;
}
} */

/* @media only screen and (max-width: 600px) {
  .hello-left {
    height: 130vh;
  }
} */
</style>