/* eslint-disable no-console */
import axios from "axios";
import enurl from "@/api/environment";
import store from "../store";
export default {
  data() {
    return {
      url: enurl.apiUrl,
    };
  },
  methods: {
    LogTrace(exception, logtrace, correlation, logtype) {
      if (exception != null && exception != undefined) {
        var description = exception.message;
      } else {
        description = "-";
      }
      let tempLog = {
        user_ID: store.state.user_ID,
        company_ID: 0,
        correlation_ID: correlation,
        logType: logtype,
        exception: description,
        logTrace: logtrace,
        application: "Optimistic Product Admin",
      };
      axios

        .post(`${this.url}Log/AddLog`, tempLog)
        .then(function() {})
        .catch(function(error) {
          alert(error);
        });
    },
  },
};
