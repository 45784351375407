<template>
  <div class="full-height" style="background-color:#fff2e3;">
    <v-container fluid fill-height>
      <v-row class="align-center justify-center">
        <!-- Left Image Column (Hidden on Small Screens) -->
        <v-col cols="12" md="6" lg="6" xl="6" class="image-column hidden-sm-and-down ma-0 pa-0">
          <v-img class="image" src="@/assets/HomeImage.png" :height="'100%'" :width="'100%'"></v-img>
        </v-col>

        <!-- Right Text Column -->
        <v-col cols="12" md="6" lg="6" xl="6" class="text-column">
          <div class="right-text-container">
            <v-container fluid>
              <!-- Welcome Text -->
              <v-row>
                <v-col cols="12" align="center">
                  <p class="text-center welcomeToHeader">Welcome to</p>
                  <p class="text-center optimisticHeader">OPTIMISTIC</p>
                </v-col>
              </v-row>

              <!-- Reset Password Text -->
              <v-row>
                <v-col cols="12" align="center">
                  <p class="text-center" style="font-size: 20px;color: #f99d20;">
                    {{ $t("ResetYourPassword") }}
                  </p>
                </v-col>
              </v-row>

              <!-- Email Instruction -->
              <v-row align="center" justify="center">
                <p class="text-center">{{ $t("PleaseEnterYourEmail") }}</p>
              </v-row>

              <!-- Alerts -->
              <v-row justify="center">
                <v-col cols="12" style="flex-basis: 500px;">
                  <v-alert type="error" v-model="erroralert">
                    {{ errorMessage }}
                  </v-alert>
                  <v-alert type="info" v-model="successalert" style="text-align: left; font-size: 14px;">
                    {{ successMessage }}
                  </v-alert>
                </v-col>
              </v-row>

              <!-- Email Input -->
              <v-row justify="center">
                <v-col cols="12" sm="8" md="8" xl="6" class="mx-auto">
                  <v-text-field color="warning" :label="$t('email')" :error-messages="error_Pass" v-model="forget_Password">
                    <template v-slot:prepend-inner>
                      <img src="@/assets/images/Mask Group 192.png" alt />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Reset Password Button -->
              <v-row justify="center">
                <v-col cols="12" sm="8" md="8" xl="6" class="mx-auto">
                  <v-btn rounded color="#F99D20" block style="height: 52px; font-size: 17px; color: white;" depressed class="text-capitalize" @click="resetPassword">
                    {{ $t("ResetPassword") }}
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Back to Login Link -->
              <v-row no-gutters>
                <v-col cols="12" sm="8" md="8" xl="6" class="mx-auto">
                  <p class="float-right" style="color: #0fa7d8;text-decoration: underline;font-size: 12px;margin-top:1rem;cursor:pointer;" @click="backToLogin()">
                    {{ $t("BackToLogin") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/Function.js";
export default {
  mixins: [LogTrace],
  data() {
    return {
      customImgHeight: "100%",
      url: enurl.apiUrl,
      forget_Password: "",
      error_Pass: "",
      erroralert: false,
      successalert: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  watch: {
    "forget_Password"(){
      this.error_Pass = [];
    },
  },
  methods: {
    backToLogin() {
      this.$router.push({ name: "Login" }).catch(()=>{});
    },
    validateEmail(email) {
      let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return valid.test(email) || "Invalid Email";
    },
    clearMessage: function () {
      this.erroralert = false;
      this.errorMessage = "";
      this.successalert = false;
      this.successMessage = "";
    },
    resetPassword() {
      let self = this;
      self.clearMessage();
      self.error_Pass = !this.forget_Password
        ? "Email is required"
        : "";
      if (self.forget_Password.length >= 1) {
        let emailvali = self.validateEmail(self.forget_Password);
        if (emailvali == true) {
          let tempp = {
            email: self.forget_Password,
          };
          axios
            .post(`${self.url}UserInfo/ForgetPasswordAdmin`, tempp)
            .then(function (response) {
              if (
                response.data.message == "Please check your User Id and Mail."
              ) {
                self.erroralert = true;
                self.errorMessage = "Mail not found in our system!";
              } else {
                self.LogTrace(null, "Forgot Password", 54, "Low");
                self.successalert = true;
                self.successMessage=self.$t("forgetpassw");
                // self.successMessage =
                //   "Please check your inbox for new password. If you did not receive an email, please check your junk mailbox. If the problem still exists, please email us at sales@fusionsol.com";
                self.forget_Password = "";
              }
            }).catch(function(error){
              alert(error);
              self.LogTrace(error, "Forgot Password Fail", 54, "Low");
            })
        } else {
          self.error_Pass = self.validateEmail(self.forget_Password);
          alert("Please fill the correct Email format");
        }
      }
    },
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-column {
  max-height: 100vh;
  overflow: hidden;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.welcomeToHeader {
  color: #0fa7d8;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  text-shadow: 0px 3px 6px #00000029;
  font-weight: bold;
}

.optimisticHeader {
  font-size: 60px;
  color: #f99d20;
  font-family: "Montserrat", sans-serif;
  margin-top: -30px;
  font-weight: bold;
}

/* Default styling for .text-column */
.text-column {
  padding: 1rem;
}

/* For screens between 428px and 960px */
@media (max-width: 960px) and (min-width: 428px) {
  .text-column {
    padding: 1rem; /* Already set in default, no need to repeat */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* For screens smaller than 428px */
@media (max-width: 428px) {
  .text-column {
    padding: 2rem;
  }
}
</style>