<template>
  <v-app>
    <Navbar v-if="$route.path != '/' && $route.path != '/forgetpassword' && $route.name != 'resetpassword'&& $route.name != 'Registation'" /> 
    <v-main  style="background-color:#f0f0f7;">
      <router-view :CountryList="CountryList" :CountrySizeList="CountrySizeList" />
    </v-main>
    
  </v-app>
</template>

<script>
import Navbar from './components/Navbar';

export default {
  name: 'App',

  components: {
    Navbar,
  },

  data: () => ({
    CountryList: [
        { val: 1, txt: "Brunei" },
        { val: 2, txt: "Cambodia" },
        { val: 3, txt: "China" },
        { val: 4, txt: "EastTimor" },
        { val: 5, txt: "Indonesia" },
        { val: 6, txt: "Japan" },
        { val: 7, txt: "Laos" },
        { val: 8, txt: "Malaysia" },
        { val: 9, txt: "Myanmar" },
        { val: 10, txt: "Philippines" },
        { val: 11, txt: "Singapore" },
        { val: 12, txt: "South Korea" },
        { val: 13, txt: "Taiwan" },
        { val: 14, txt: "Thailand" },
        { val: 15, txt: "Vietnam" },
        { val: 16, txt: "Other" }
    ],
    CountrySizeList: [
        { val: 1, txt: "1-10" },
        { val: 2, txt: "11-30" },
        { val: 3, txt: "31-50" },
        { val: 4, txt: "51-70" },
        { val: 5, txt: "71-100" },
        { val: 6, txt: "101-150" },
        { val: 7, txt: "150-200" },
        { val: 8, txt: "201-500" },
        { val: 9, txt: "500-1000" },
        { val: 10, txt: "1000 up" },
    ],
  }),
};
</script>


<style>
* {
  font-family: "Kanit"!important;
}

.kanit-medium {
  font-family: "Kanit";
}

.text_transform_none {
  text-transform: none;
}
.dialogmargin{
  margin-top: 20px!important;
  margin-bottom: 20px!important;
}
.headertext{
  font-size: 25px!important;
  font-family: "Kanit";
}
.editIcon {
    width: 26px;
    height: 26px;
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(79%) sepia(0%) saturate(4341%) hue-rotate(26deg) brightness(107%) contrast(81%);
}

.editIcon:hover {
    width: 26px;
    height: 26px;
    filter: invert(69%) sepia(8%) saturate(3775%) hue-rotate(356deg) brightness(96%) contrast(92%);
}

.deleteIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(79%) sepia(0%) saturate(4341%) hue-rotate(26deg) brightness(107%) contrast(81%);
}

.deleteIcon:hover {
    width: 24px;
    height: 24px;
    cursor: pointer;
    filter: invert(40%) sepia(80%) saturate(1474%) hue-rotate(358deg) brightness(91%) contrast(83%);
}
.popupdialog{
  border-radius: 20px!important;
}

.txt-header
{
  font-size: 25px; 
  color: #43425d; 
}
</style>

<style scoped>
::v-deep .custom-vuedatepicker .mx-input{
  height: 48px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-top:none !important;
  border-left:none !important;
  border-right:none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  padding: 5.5px !important; /* Remove all padding */
  line-height: normal; /* Ensure line-height is normal */
  height: auto; /* Ensure height is auto to remove extra space */
  outline: none;
  border-color: #717171 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker .mx-input:disabled, .mx-input.disabled {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.38);
    cursor: default !important;
    border-bottom: 1px dotted #717171;
}

::v-deep .custom-vuedatepicker .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}

::v-deep .custom-vuedatepicker-er-mg .mx-input{
  height: 48px !important;
  /* box-shadow: none !important; */
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-radius: 0 !important;
  padding: 5.5px !important; /* Remove all padding */
  line-height: normal; /* Ensure line-height is normal */
  height: auto; /* Ensure height is auto to remove extra space */
  outline: none;
  border-color: #FF4B55 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker-er-mg .mx-input:disabled, .mx-input.disabled {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.38);
    cursor: default !important;
    border-bottom: 1px dotted #717171;
}

::v-deep .custom-vuedatepicker-er-mg .mx-input:focus{
  border-color: #f89d1f!important;
}
::v-deep .custom-vuedatepicker-er-mg .mx-icon-calendar {
  color: #FF4B55 !important;
}
::v-deep .custom-vuedatepicker-er-mg .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker-er-mg .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}

::v-deep .custom-vuedatepicker1 .mx-input{
  height: 48px !important;
  /* box-shadow: none !important; */
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-color: #717171 !important;
  border: 1px solid #717171!important ;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker1 .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker2 .mx-input{
  height: 40px !important;
  font-size: 16px !important;
  border-color: #717171 !important;
  border: 1px solid #717171!important ;
}

::v-deep .custom-vuedatepicker2 .mx-input:focus{
  border-color: #f89d1f!important;
}
::v-deep .custom-vuedatepicker2 .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker2 .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}

::v-deep .custom-vuedatepicker1 .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker1 .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}
::v-deep .custom-vuedatepicker-er-mg1 .mx-input{
  height: 40px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-family: 'Kanit' !important;
  border-color: #FF4B55 !important;
  /* margin-top: 1rem; */
}

::v-deep .custom-vuedatepicker-er-mg1 .mx-input:focus{
  border-color: #f89d1f!important;
}

::v-deep .custom-vuedatepicker-er-mg1 .mx-input:focus + .mx-icon-calendar,
::v-deep .custom-vuedatepicker-er-mg1 .mx-input:focus .mx-icon-calendar {
  color: #f89d1f !important;
}
::v-deep .custom-vuedatepicker-er-mg1 .mx-icon-calendar {
  color: #FF4B55 !important;
}
</style>