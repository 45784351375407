<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div v-show="addcompanyadd">
      <div style="margin-left: 4px;margin-top: 22px;display:flex;">
        <v-btn router icon color="white" :to="{ name: 'CompanyManagement' }">
          <img src="@/assets/images/back button.png" class="mt-1" />
        </v-btn>
        <p class="txt-header">
          {{ $t("newCompany") }}
        </p>
      </div>
      <!-- <div style="background-color: #f0f0f7">
        <v-row>
          <v-btn
            icon
            router
            color="white"
            :to="{ name: 'CompanyManagement' }"
            class="mt-7 ml-3"
          >
            <img src="@/assets/images/back button.png" class="mt-1 ml-3" />
          </v-btn>
          <p
            class="headertext mb-0"
            style="
              margin-left: 4px;
              margin-top: 22px;
            "
          >
            {{ $t("newCompany") }}
          </p>
        </v-row>
      </div> -->
      
      <v-card class="mt-3 mb-5 card" style="margin-bottom: 5rem">
        <div class="d-flex">
          <div class="pt-6 ml-6">
            <img
              width="24px"
              height="24px"
              style="margin-top:auto;margin:bottom:auto;"
              src="@/assets/images/information.png"
            />
          </div>
          <div class="ml-3 pt-6">
            <label style="font-size: 16px; color: #f99d20">{{
              $t("generalInformation")
            }}</label>
          </div>
        </div>
        <v-form ref="form" lazy-validation>
          <div class="content" style="margin-left: 2rem; margin-right: 2rem">
            <v-row>
              <v-col cols="8" md="8" sm="6">
                <label style="font-size: 14px"
                  >{{ $t("companyName")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  color="#F99D20"
                  dense
                  class="empID"
                  v-model="company.name"
                  :rules="nameAdd"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("companyCode")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  class="empID"
                  
                  maxlength="5"
                  type="text"
                  v-model="company.code"
                  :rules="codeAdd"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("companysize")
                  }}<span class="red--text"> *</span></label
                >
                <v-select
                  class="empID"
                  v-model="company.companysize"
                  :items="CountrySizeList"
                  item-value="val"
                  :item-text="(item) => ` ${item.txt}`"
                  append-icon="mdi-chevron-down"
                  :rules="sizeAdd"
                  color="#F99D20"
                  style="margin-top: -0.4rem"
                >
                </v-select>
                <!-- <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  class="empID"
                  @keypress="onlyNumber"
                  v-model="company.companysize"
                  :rules="sizeAdd"
                ></v-text-field> -->
              </v-col>
            </v-row>
             <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("Businesstype")
                  }}<span class="red--text"> *</span></label
                >

                <v-autocomplete
                  
                  v-model="company.businesstype"
                  :items="itemss"
                  @change="checkbusinesstypeforadd"
                  dense
                  :rules="BusinessAdd"
                  item-value="val"
                  :item-text="(item) => ` ${item.text}`"
                  append-icon="mdi-chevron-down"
                  color="#F99D20"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="showothertype">
                <label style="font-size: 14px"
                  >Other types<span class="red--text"> *</span></label
                >

                <v-text-field
                  autocomplete="off"
                  dense
                  class="empID"
                  v-model="company.othertype"
                  :rules="OthertypeAdd"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("telephone_number")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.teleNumber"
                  :rules="phoneRules"
                  v-mask="mask"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("email") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.email"
                  :rules="emailRules"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("country") }}<span class="red--text"> *</span></label
                >
                <v-autocomplete
                  class="empID"
                  v-model="company.country"
                  :items="CountryList"
                  item-value="txt"
                  :item-text="(item) => ` ${item.txt}`"
                  append-icon="mdi-chevron-down"
                  :rules="countryAdd"
                  color="#F99D20"
                  style="margin-top: -0.1rem"
                >
                </v-autocomplete>
                <!-- <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.country"
                  :rules="countryAdd"
                  dense
                  class="empID"
                ></v-text-field> -->
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("city") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.city"
                  :rules="cityAdd"
                  dense
                  class="empID mt-1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("address") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.address"
                  :rules="addressAdd"
                  style="margin-top: 6px"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("ContactName")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="company.contactname"
                  :rules="ContactAdd"
                  style="margin-top: 6px"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Add Register Date && Expire Date -->
            <v-row>
              <!-- Add Register Date -->
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("register_Date")
                  }}<span class="red--text"> *</span></label
                >
                <date-picker v-bind:clearable="false" 
                  format="DD/MM/YYYY" 
                  :style="{ width: '100%' }" readonly 
                  v-model="date10" 
                  value-type="YYYY-MM-DD"
                  :error-messages="date10Add"
                  :editable="false" class="custom-vuedatepicker">
                  <!-- <template slot="icon-calendar">
                      <v-icon>
                          mdi-calendar-month-outline
                      </v-icon>
                  </template> -->
                </date-picker>
                <!-- <span v-if="date10Add" class="error-message">{{ date10Add }}</span> -->
                <!-- <v-menu
                  v-model="menu10"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      append-icon="mdi-calendar-month-outline"
                      v-model="computedDateFormatted10"
                      :rules="date10Add"
                      format="DD/MM/yyyy"
                      readonly
                      class="kanit-medium pa-0 ma-0"
                      color="#F99D20"
                      persistent-hint
                      v-on="on"
                      style="line-height: 0px"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date10"
                    no-title
                    @input="menu10 = false"
                  ></v-date-picker>
                </v-menu> -->
              </v-col>

              <!-- Add Expire Date -->
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("expired_date")
                  }}<span class="red--text"> *</span></label
                >
                <date-picker v-bind:clearable="false" 
                  format="DD/MM/YYYY" 
                  :style="{ width: '100%' }" readonly 
                  v-model="date7"
                  value-type="YYYY-MM-DD"
                  :disabled-date="allowedEndDates"
                  :error-messages="date7Add"
                  :editable="false" class="custom-vuedatepicker">
                  <!-- <template slot="icon-calendar">
                      <v-icon>
                          mdi-calendar-month-outline
                      </v-icon>
                  </template> -->
                </date-picker>
                <!-- <v-menu
                  v-model="menu7"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      append-icon="mdi-calendar-month-outline"
                      v-model="computedDateFormatted7"
                      :rules="date7Add"
                      readonly
                      single-line
                      class="kanit-medium pa-0 ma-0"
                      color="#F99D20"
                      persistent-hint
                      v-on="on"
                      style="line-height: 0px"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date7"
                    no-title
                    :allowed-dates="allowedDates"
                    @input="menu7 = false"
                  ></v-date-picker>
                </v-menu> -->
              </v-col>
            </v-row>
          </div>
          <!-- package plan -->
          <div class="d-flex">
            <div class="mt-3 ml-7">
              <img
                width="24px"
                height="24px"
                style="margin-top:auto;margin:bottom:auto;"
                src="@/assets/images/Icon feather-tag@2x.png"
              />
            </div>
            <div class="ml-3 mt-3">
              <label style="font-size: 16px; color: #f99d20"
                >{{ $t("packageplan") }}
              </label>
            </div>
          </div>
          <div class="content" style="margin-left: 2rem; margin-right: 2rem">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("packagename")
                  }}<span class="red--text"> *</span></label
                >
                <v-select
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="packageList"
                  v-model="company.packname"
                  append-icon="mdi-chevron-down"
                  v-on:change="getthatnumber(company.packname, 1)"
                  :rules="pnameAdd"
                  color="#F89D1F"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("activemps") }}<span class="red--text"> *</span></label
                >
                <v-select
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="ActiveEmpList"
                  v-model="company.empnum"
                  append-icon="mdi-chevron-down"
                  :rules="enumAdd"
                  color="#F89D1F"
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <!-- user account -->
          <div class="d-flex">
            <div class="mt-3 ml-7">
              <img
                width="24px"
                height="24px"
                style="margin-top:auto;margin:bottom:auto;"
                src="@/assets/images/infoForUser.png"
              />
            </div>
            <div class="ml-3 mt-3">
              <label style="font-size: 16px; color: #f99d20">{{
                $t("userInformation")
              }}</label>
            </div>
          </div>
          <div class="content" style="margin-left: 2rem; margin-right: 2rem">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("userName") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  v-model="uid"
                  color="#F99D20"
                  :rules="uidAdd"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4">
                <label style="font-size: 14px"
                  >{{ $t("password") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  v-model="pwd"
                  color="#F99D20"
                  :rules="pwdAdd"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="3" class="pl-5">
                <v-btn
                  :disabled="lockdisable"
                  class="psw"
                  icon
                  @click="showlock = !showlock"
                >
                  <img :src="showlock ? lockimage.lock : lockimage.unlock" />
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <div style="height: 7vh"></div>
       
        <!-- <v-card-actions class="pb-6 mr-5">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            height="40"
            text
            router
            :to="{ name: 'CompanyManagement' }">
            {{ $t("cancel1") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-4 text-capitalize"
            width="120"
            height="40"
            @click="AddCompany()">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions> -->
        <v-card-actions class="pb-6 mr-5">
          <v-row class="align-center">
            <v-col cols="12" lg="9" xl="9" md="9" sm="12" class="d-flex justify-start">

            </v-col>
            <v-col cols="12" lg="3" xl="3" md="3" sm="12" class="d-flex justify-end">
              <v-btn
                class="mr-2 text-capitalize"
                width="120"
                height="40"
                text
                router
                :to="{ name: 'CompanyManagement' }">
                {{ $t("cancel1") }}
              </v-btn>
              <v-btn
                color="#F99D20"
                class="mr-4 text-capitalize"
                width="120"
                height="40"
                @click="AddCompany()">
                {{ $t("save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>

    <div v-show="!addcompanyadd">
      <div style="margin-left: 4px;margin-top: 22px;display:flex;">
        <v-btn router icon color="white" :to="{ name: 'CompanyManagement' }">
          <img src="@/assets/images/back button.png" class="mt-1" />
        </v-btn>
        <p class="txt-header">
          {{ $t("editCompany") }}
        </p>
      </div>

      <v-card class="dialogmargin card" style="margin-bottom: 5rem">
        <div class="d-flex pt-3">
          <div class="pt-3 ml-7">
            <img
              width="24px"
              height="24px"
              style="margin-top:auto;margin:bottom:auto;"
              src="@/assets/images/information.png"
            />
          </div>
          <div class="ml-3 mt-3">
            <label style="font-size: 16px; color: #f99d20">
              {{ $t("generalInformation") }}
            </label>
          </div>
        </div>
        <v-form ref="formEdit">
          <div class="content" style="margin-left: 2rem; margin-right: 2rem">
            <v-row>
              <v-col cols="8" md="8" sm="6">
                <label style="font-size: 14px">
                  {{ $t("companyName") }}<span class="red--text"> *</span>
                </label>
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  dense
                  class="empID"
                  v-model="edit.name"
                  :rules="nameEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("companyCode")
                  }}<span class="red--text"> *</span></label
                >

                <v-text-field
                  autocomplete="off"
                  dense
                  class="empID"
                  onkeypress=""
                  maxlength="5"
                  type="text"
                  color="#F99D20"
                  v-model="edit.code"
                  :rules="codeEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("companysize")
                  }}<span class="red--text"> *</span></label
                >
                <v-select
                  class="empID"
                  v-model="edit.companysize"
                  :items="CountrySizeList"
                  item-value="val"
                  :item-text="(item) => ` ${item.txt}`"
                  append-icon="mdi-chevron-down"
                  :rules="sizeEdit"
                  color="#F99D20"
                  style="margin-top: -0.4rem"
                >
                </v-select>

                <!-- <v-text-field
                  autocomplete="off"
                  dense
                  class="empID"
                  @keypress="onlyNumber"
                  v-model="edit.companysize"
                  :rules="sizeEdit"
                ></v-text-field> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("Businesstype")
                  }}<span class="red--text"> *</span></label
                >

                <v-autocomplete
                  hide-details
                  v-model="edit.businesstype"
                  :items="itemss"
                  @change="checkbusinesstypeforupdate"
                  dense
                  :rules="BusinessEdit"
                  item-value="val"
                  :item-text="(item) => ` ${item.text}`"
                  append-icon="mdi-chevron-down"
                  color="#F99D20"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="showothertype">
                <label style="font-size: 14px"
                  >Other types<span class="red--text"> *</span></label
                >

                <v-text-field
                  autocomplete="off"
                  dense
                  class="empID"
                  v-model="edit.othertype"
                  :rules="OthertypeEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("telephone_number")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edit.teleNumber"
                  :rules="phoneRulesEdit"
                  v-mask="mask"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("email") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edit.email"
                  :rules="emailRulesEdit"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("country") }}<span class="red--text"> *</span></label
                >
                <v-autocomplete
                  class="empID"
                  v-model="edit.country"
                  :items="CountryList"
                  item-value="txt"
                  :item-text="(item) => ` ${item.txt}`"
                  append-icon="mdi-chevron-down"
                  :rules="countryEdit"
                  color="#F99D20"
                  style="margin-top: -0.3rem"
                >
                </v-autocomplete>
                <!-- <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edit.country"
                  :rules="countryEdit"
                  dense
                  class="empID"
                ></v-text-field> -->
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("city") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edit.city"
                  :rules="cityEdit"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("address") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edit.address"
                  :rules="addressEdit"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("ContactName")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  v-model="edit.contactname"
                  :rules="ContactEdit"
                  dense
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("register_Date")
                  }}<span class="red--text"> *</span></label
                >
                <date-picker class="custom-vuedatepicker" 
                  v-bind:clearable="false" 
                  value-type="YYYY-MM-DD" 
                  v-model="registerDate"
                  no-title format="DD/MM/YYYY" 
                  readonly
                  :style="{ width: '100%' }">
                  <!-- <template slot="icon-calendar">
                      <v-icon>
                          mdi-calendar-month-outline
                      </v-icon>
                  </template> -->
                </date-picker>
                <!-- <v-menu
                  v-model="menu9"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      append-icon="mdi-calendar-month-outline"
                      v-model="computedDateFormatted9"
                      :rules="
                        [required('Registration Date')] || registerDateEdit
                      "
                      readonly
                      class="kanit-medium"
                      color="#F99D20"
                      persistent-hint
                      v-on="on"
                      ><template v-slot:label>
                        <span>
                          {{ $t("register_Date")
                          }}<span class="red--text"> *</span></span
                        >
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="registerDate"
                    no-title
                    @input="menu9 = false"
                  ></v-date-picker>
                </v-menu> -->
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("expired_date")
                  }}<span class="red--text"> *</span></label
                >
                <date-picker class="custom-vuedatepicker" 
                  v-bind:clearable="false" 
                  value-type="YYYY-MM-DD" 
                  v-model="expireddate" 
                  :disabled-date="allowedEndDates" 
                  no-title format="DD/MM/YYYY" 
                  readonly
                  :style="{ width: '100%' }">
                  <!-- <template slot="icon-calendar">
                      <v-icon>
                          mdi-calendar-month-outline
                      </v-icon>
                  </template> -->
                </date-picker>
                <!-- <v-menu
                  v-model="menu8"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      autocomplete="off"
                      append-icon="mdi-calendar-month-outline"
                      v-model="computedDateFormatted8"
                      :rules="[required('Expiry Date')] || expireddateEdit"
                      readonly
                      class="kanit-medium"
                      color="#F99D20"
                      persistent-hint
                      v-on="on"
                      ><template v-slot:label>
                        <span>
                          {{ $t("expired_date")
                          }}<span class="red--text"> *</span></span
                        >
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expireddate"
                    no-title
                    :allowed-dates="allowedDates"
                    @input="menu8 = false"
                  ></v-date-picker>
                </v-menu> -->
              </v-col>
            </v-row>
          </div>
          <!-- package plan -->
          <div class="d-flex">
            <div class="mt-3 ml-7">
              <img
                width="24px"
                height="24px"
                style="margin-top:auto;margin:bottom:auto;"
                src="@/assets/images/Icon feather-tag@2x.png"
              />
            </div>
            <div class="ml-3 mt-3">
              <label style="font-size: 16px; color: #f99d20"
                >{{ $t("packageplan") }}
              </label>
            </div>
          </div>
          <div class="content" style="margin-left: 2rem; margin-right: 2rem">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("packagename")
                  }}<span class="red--text"> *</span></label
                >
                <v-select
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="packageList"
                  v-model="edit.packname"
                  append-icon="mdi-chevron-down"
                  v-on:change="getthatnumber(edit.packname)"
                  :rules="pnameEdit"
                  color="#F89D1F"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("activemps") }}<span class="red--text"> *</span></label
                >
                <v-select
                  class="empID"
                  style="margin-top: 0.95rem"
                  :items="ActiveEmpList"
                  v-model="edit.empnum"
                  append-icon="mdi-chevron-down"
                  :rules="enumEdit"
                  color="#F89D1F"
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <!-- user account -->
          <div class="d-flex">
            <div class="mt-3 ml-7">
              <img
                width="24px"
                height="24px"
                style="margin-top:auto;margin:bottom:auto;"
                src="@/assets/images/infoForUser.png"
              />
            </div>
            <div class="ml-3 mt-3">
              <label style="font-size: 16px; color: #f99d20">{{
                $t("userInformation")
              }}</label>
            </div>
          </div>
          <div class="content" style="margin-left: 2rem; margin-right: 2rem">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 14px"
                  >{{ $t("username") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  v-model="edit.uid"
                  :rules="uidEdit"
                  color="#F99D20"
                  class="empID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4" class="pb-0">
                <label style="font-size: 14px"
                  >{{ $t("password") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  :type="show1 ? 'text' : 'password'"
                  :rules="pwdEdit"
                  v-on:keyup="keymonitor"
                  v-model="edit.pwd"
                  class="empID"
                  color="#F99D20"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="3" class="pl-3 pb-0">
                <v-btn
                  :disabled="lockdisable"
                  class="psw"
                  icon
                  @click="showlock = !showlock"
                >
                  <img :src="showlock ? lockimage.lock : lockimage.unlock" />
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <div style="height: 7vh"></div>

        <v-card-actions class="pb-6 mr-5">
          <v-row class="align-center">
            <v-col cols="12" lg="9" xl="9" md="9" sm="12" class="d-flex justify-start">
              <v-btn
                width="350"
                color="#E9E9F0"
                class="orange--text text-capitalize addbtn"
                style="font-weight: normal;margin-left: 1.6rem;"
                max-height="35"
                @click="OpenMailingDialog()">
                <img
                  width="30"
                  height="20"
                  src="@/assets/images/Icon feather-mail@2x.png"
                  class="pr-2"
                />
                {{ $t("sentnewpwd") }}
              </v-btn>
            </v-col>
            <v-col cols="12" lg="3" xl="3" md="3" sm="12" class="d-flex justify-end">
              <v-btn
                class="mr-2 text-capitalize"
                width="120"
                height="40"
                text
                router
                :to="{ name: 'CompanyManagement' }">
                {{ $t("cancel1") }}
              </v-btn>
              <v-btn
                color="#F99D20"
                class="mr-4 text-capitalize"
                width="120"
                height="40"
                v-on:click="myupdatecompany()">
                {{ $t("Update") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
    
    <v-dialog v-model="SendEmaildialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("sendingInfo") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-form ref="formSend">
            <v-container>
              <v-row>
                <v-col align="center" cols="12">
                  <v-row class="ml-5">
                    <p style="font-size: 17px; color: #444444" class="pt-6">
                      {{ $t("sentfrom") }}
                    </p>
                    <v-text-field
                      autocomplete="off"
                      color="#F99D20"
                      v-model="comname"
                      readonly
                      style="
                        margin-left: 5px;
                        margin-top: 21px;
                        max-width: 200px;
                      "
                      dense
                      class="empID ct"
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col align="center" cols="12">
                  <v-row class="ml-5">
                    <p style="font-size: 17px; color: #444444" class="pt-1">
                      {{ $t("sentto") }}
                    </p>
                    <v-text-field
                      autocomplete="off"
                      color="#F99D20"
                      v-model="sentTo"
                      :rules="sentToemailRules"
                      style="
                        margin-left: 5px;
                        margin-top: 1px;
                        max-width: 350px;
                      "
                      dense
                      class="empID"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeMaildialog"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="SendMail"
            >{{ $t("Send") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  
  </div>
</template>

<script>
import enurl from "@/api/environment";
import axios from "axios";
import LogTrace from "@/api/Function.js";
import { mask } from "vue-the-mask";
import LoadingDialog from "@/components/Loading.vue";
export default {
  mixins: [LogTrace],
  props: ['CountryList', 'CountrySizeList'],
  directives: {
    mask,
  },
  components:{
    LoadingDialog,
  },
  watch: {
    "edit.name"() {
      this.nameEdit = [];
    },
    "edit.code"() {
      this.codeEdit = [];
    },
    "edit.companysize"() {
      this.sizeEdit = [];
    },
    "edit.businesstype"(v) {
      this.BusinessEdit = [];
      if (v == 30) {
        this.showothertype = true;
      } else {
        this.showothertype = false;
      }
    },
    "edit.othertype"() {
      this.OthertypeEdit = [];
    },
    "edit.teleNumber"() {
      this.phoneRulesEdit = [];
    },
    "edit.email"() {
      this.emailRulesEdit = [];
    },
    "edit.country"() {
      this.countryEdit = [];
    },
    "edit.city"() {
      this.cityEdit = [];
    },
    "edit.address"() {
      this.addressEdit = [];
    },
    "edit.contactname"() {
      this.ContactEdit = [];
    },
    expireddate() {
      this.expireddateEdit = [];
    },
    registerDate(v) {
      this.registerDateEdit = [];
      if (new Date(v) > new Date(this.expireddate)) {
        this.expireddate = null;
      }
    },
    "edit.packname"() {
      this.pnameEdit = [];
    },
    "edit.empnum"() {
      this.enumEdit = [];
    },
    "edit.uid"() {
      this.uidEdit = [];
    },
    "edit.pwd"() {
      this.pwdEdit = [];
    },
    "company.companysize"() {
      this.sizeAdd = [];
    },
    "company.businesstype"(v) {
      this.BusinessAdd = [];
      if (v == 30) {
        this.showothertype = true;
      } else {
        this.showothertype = false;
      }
    },
    "company.othertype"() {
      this.OthertypeAdd = [];
    },
    "company.name"() {
      this.nameAdd = [];
    },
    "company.code"() {
      this.codeAdd = [];
    },
    "company.email"() {
      this.emailRules = [];
    },
    "company.teleNumber"() {
      this.phoneRules = [];
    },
    "company.country"() {
      this.countryAdd = [];
    },
    "company.city"() {
      this.cityAdd = [];
    },
    "company.address"() {
      this.addressAdd = [];
    },
    "company.contactname"() {
      this.ContactAdd = [];
    },
    "company.packname"() {
      this.pnameAdd = [];
    },
    "company.empnum"() {
      this.enumAdd = [];
    },
    date7() {
      this.date7Add = [];
    },
    date10(v) {
      this.date10Add = [];
      if (new Date(v) > new Date(this.date7)) {
        this.date7 = null;
      }
    },
    uid() {
      this.uidAdd = [];
    },
    pwd() {
      this.pwdAdd = [];
    },
    sentTo() {
      this.sentToemailRules = [];
    },
  },
  data: (vm) => ({
    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    emailRules: [],
    phoneRules: [],
    phoneRulesEdit: [],
    emailRulesEdit: [],
    sentToemailRules: [],
    required(prop) {
      return (v) => !!v || `${prop} is required`;
    },
    minLength(prop, minLength) {
      return (v) =>
        (!!v && v.length >= minLength) ||
        `${prop} must be at least ${minLength} number`;
    },
    // emailRules: [
    //   (v) => !!v || "E-mail is required",
    //   (v) => /.+@.+/.test(v) || "E-mail must be valid",
    // ],

    // emailRules: [
    //     v => !!v || 'Please Fill in Required Fill',
    //     v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    //   ],
    showothertype: false,
    tempoArray: [],
    nameAdd: [],
    codeAdd: [],
    emailAdd: [],
    countryAdd: [],
    ContactAdd: [],
    sizeAdd: [],
    BusinessAdd: [],
    OthertypeAdd: [],
    cityAdd: [],
    addressAdd: [],
    date7Add: [],
    date10Add: [],
    pnameAdd: [],
    enumAdd: [],
    uidAdd: [],
    pwdAdd: [],
    nameEdit: [],
    codeEdit: [],

    countryEdit: [],
    ContactEdit: [],
    sizeEdit: [],
    BusinessEdit: [],
    OthertypeEdit: [],
    cityEdit: [],
    addressEdit: [],
    registerDateEdit: [],
    expireddateEdit: [],
    pnameEdit: [],
    enumEdit: [],
    uidEdit: [],
    pwdEdit: [],
    loadingdialog: false,
    show1: false,
    update: false,
    companyList: [],
    packageList: [],
    ActiveEmpList: [],
    compID: 0,
    addcompanyadd: true,
    disabled: false,
    SendEmaildialog: false,
    company: {
      code: null,
      name: null,
      companysize: "",
      businesstype: "",
      othertype: "",
      teleNumber: null,
      city: null,
      country: null,
      email: null,
      address: null,
      contactname: null,
      packname: null,
      empnum: null,
    },
    showlock: false,
    lockdisable: true,
    lockimage: {
      lock: require("@/assets/images/padlock.png"),
      unlock: require("@/assets/images/padunlock.png"),
    },
    addroute: null,
    uid: null,
    pwd: null,
    comname: null,
    sentTo: null,
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date7: "",
    date10: "",
    menu7: false,
    menu8: false,
    menu9: false,
    menu10: false,
    edit: {
      code: null,
      name: "",
      companysize: "",
      businesstype: "",
      othertype: "",
      teleNumber: null,
      city: null,
      country: null,
      email: null,
      address: null,
      uid: null,
      pwd: null,
      compID: 0,
      contactname: null,
      packname: null,
      empnum: null,
      company_ID: null,
    },
    registerDate: "",
    expireddate: "",

    mask: "###########",
    maskcode: "AAAAA",
    characters: "a-z,A-Z,0-9",
    size: 10,
    tempnum: 0,
    url: enurl.apiUrl,
    error: {
      errorMessagename: "",
      errorMessagecode: "",
      errorMessageph: "",
      errorMessageemail: "",
      errorMessagecountry: "",
      errorMessagecity: "",
      errorMessageadd: "",
      errorMessagedate: "",
      errorMessageuid: "",
      errorMessagepsw: "",
      errorMessagecontactname: "",
    },
    ComList: [],
    usernamelist: [],
    userid: [],
  }),

  async mounted() {
    try
    {
      let self = this;
      self.loadingdialog = true;
      await self.GetPackage();
      await self.ShowHide();
      await self.Editemployee();
      await self.GetAllCompanyList();
      await self.GetUserName();
      self.pwd = self.generate();
      self.uid = self.generateUserID();
      self.date10 = String(
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1 >= 10
            ? new Date().getMonth() + 1
            : "0" + (new Date().getMonth() + 1)) +
          "-" +
          (new Date().getDate() >= 10
            ? new Date().getDate()
            : "0" + new Date().getDate())
      );
      self.loadingdialog = false;
    }
    catch(Exception)
    {
      self.loadingdialog = false;
      alert ("error")
    }
  },

  created() {
    this.addroute = this.$route.params.addcompany;
  },

  computed: {
    itemss() {
      return [
        { text: this.$t("Individual"), val: 1 },
        { text: this.$t("Hotel / Resort"), val: 2 },
        { text: this.$t("Restaurant"), val: 3 },
        { text: this.$t("Factory"), val: 4 },
        { text: this.$t("Office"), val: 5 },
        { text: this.$t("Merchandise"), val: 6 },
        { text: this.$t("Gas"), val: 7 },
        { text: this.$t("Securitycom"), val: 8 },
        { text: this.$t("Construction"), val: 9 },
        { text: this.$t("Transportcom"), val: 10 },
        { text: this.$t("Hospital"), val: 11 },
        { text: this.$t("Clinic"), val: 12 },
        { text: this.$t("School"), val: 13 },
        { text: this.$t("techIT"), val: 14 },
        { text: this.$t("Accounting"), val: 15 },
        { text: this.$t("Organizercom"), val: 16 },
        { text: this.$t("Marketcom"), val: 17 },
        { text: this.$t("Finance"), val: 18 },
        { text: this.$t("Insurance"), val: 19 },
        { text: this.$t("Outsourcing"), val: 20 },
        { text: this.$t("Personalcare"), val: 21 },
        { text: this.$t("Carshow"), val: 22 },
        { text: this.$t("Carcage"), val: 23 },
        { text: this.$t("Dormitory"), val: 24 },
        { text: this.$t("Association"), val: 25 },
        { text: this.$t("RentalCompany"), val: 26 },
        { text: this.$t("Service"), val: 27 },
        { text: this.$t("Tourism"), val: 28 },
        { text: this.$t("Transport"), val: 29 },
        { text: this.$t("Other"), val: 30 },
      ];
    },
    computedDateFormatted7() {
      return this.formatDate(this.date7);
    },
    computedDateFormatted8() {
      return this.formatDate(this.expireddate);
    },
    computedDateFormatted9() {
      return this.formatDate(this.registerDate);
    },
    computedDateFormatted10() {
      return this.formatDate(this.date10);
    },
  },
  methods: {
    allowedEndDates(date) {
      if (!this.date10) return false;
      const startDate = new Date(this.date10);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(date);
      endDate.setHours(0, 0, 0, 0); 
      return endDate < startDate;
    },

    checkbusinesstypeforupdate() {
      if (this.edit.businesstype == 30)
      {
        this.showothertype = true;
      }
      else
      {
        this.showothertype = false;
      }
    },

     checkbusinesstypeforadd() {
      if (this.company.businesstype == 30)
      {
        this.showothertype = true;
      }
      else
      {
        this.showothertype = false;
      }
    },

    async GetAllCompanyList() {
      let self = this;
      await axios.get(`${self.url}Company/GetCompany`).then(function (response) {
        if (response.data.status == 0)
        {
          let conlist = response.data.data;
          self.ComList = conlist.filter(
            (e) => e.company_ID != 0 && !e.activeFlag
          );
        }
        else
        {
          alert(response.data.message);
        }
      });
    },

    async GetUserName() {
      let self = this;
      await axios.get(`${self.url}UserInfo/GetUserInfos`).then(function (response) {
        if (response.data.status == 0)
        {
          self.usernamelist = response.data.data;
        }
        else
        {
          alert(response.data.message);
        }
      });
    },

    Emailvalidate(v) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v);
    },

    GetUserIDByUserName(username) {
      let self = this;
      let temp = { username: username };
      axios.post(`${self.url}UserInfo/GetUserbyCompanyName`, temp)
        .then(function (response) {
          if(response.data.status === 0)
          {
            self.userid = response.data.data;
          }
          else
          {
            alert(response.data.message);
          }
        }).catch();
    },

    OpenMailingDialog() {
      let self = this;
      self.SendEmaildialog = true;
      self.comname = self.companyList.companyName;
      self.sentTo = self.companyList.company_Email;
    },

    closeMaildialog() {
      let self = this;
      self.comname = null;
      self.sentTo = null;
      self.SendEmaildialog = false;
    },

    SendMail() {
      let self = this;
      self.loadingdialog = true;
      self.compID = parseInt(self.$route.params.id);
      self.sentToemailRules = [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
      let emailvalidate = self.$refs.formSend.validate();
      if (emailvalidate && self.sentTo != null && self.sentTo != "" && self.checkEmail(self.sentTo)) 
      {
        let pwd = self.generate();
        let tempData = {
          company_ID: self.compID,
          companyCode: self.companyList.companyCode,
          email: self.sentTo,
          username: self.companyList.username,
          password: pwd,
          is_Locked: self.showlock,
        };
        axios.post(`${self.url}Company/MailingCompanyInfo`, tempData)
        .then(function (response) {
          self.loadingdialog = false;
          if(response.data.status === 0)
          {
            self.sentTo = null;
            self.SendEmaildialog = false;
            alert("Mail Sent Successfully");
            self.Editemployee();
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Send CompanyInfo Mail Fail", 12, "Low");
          self.loadingdialog = false;
          });
      } 
      else 
      {
        self.loadingdialog = false;
      }
    },

    ValidateAccount() {
      let self = this;
      let FilterUserNameList = self.usernamelist;
      if (FilterUserNameList.some(item => item.userName.toLowerCase() === self.uid.toLowerCase())) 
      {
        alert("This username is already taken by another user. Please use a different username.");
        return false;
      }
      return true;
    },

    ValidateAccountForUpdate() {
      let self = this;
      let userName = self.edit.uid.toLowerCase;
      let FilterUserNameList = self.usernamelist.filter((person) => person.userId != self.userid[0].user_ID);
      for (let employee of FilterUserNameList)
      {
        if (userName === employee.userName.toLowerCase())
        {
          alert("This username is already taken by another user. Please use a different username.");
          return false;
        }
      }
      return true;
    },

    async GetPackage() {
      let self = this;
      let theLii = [];
      await axios.get(`${self.url}Package_Plan/GetPackagePlan`)
      .then(function (response) {
        if(response.data.status == 0)
        {
          self.tempoArray = response.data.data;
          theLii = self.tempoArray.map(item => item.pkgName);
          self.packageList = [...new Set(theLii)];
          if (self.tempnum != null) 
          {
            self.getthatnumber(self.edit.packname);
            self.edit.empnum = self.tempnum;
          }
        }
        else
        {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },

    getthatnumber(name, num) {
      let self = this;
      self.ActiveEmpList = [];
      self.company.empnum = null;
      self.edit.empnum = null;
      let theLii = [];
      theLii = self.tempoArray.filter(item => item.pkgName === name).map(filteredItem => filteredItem.activeEmp);
      if(name == "Free Trial" && num == 1)
      {
        const currentDate = new Date();
        // Add 90 days to the current date
        currentDate.setDate(currentDate.getDate() + 60);
        // Format the future date as a string (e.g., YYYY-MM-DD)
        self.date7 = currentDate.toISOString().split('T')[0];
      }
      self.ActiveEmpList = [...new Set(theLii)];
    },

    whattheAddID() {
      let self = this;
      let id = "";
      const matchingItem = self.tempoArray.find(item => item.pkgName === self.company.packname && item.activeEmp === self.company.empnum);
      if (matchingItem)
      {
        id = matchingItem.pid;
      }
      return id;
    },

    whattheEditID() {
      let self = this;
      let id = "";
      const matchingItem = self.tempoArray.find(item => item.pkgName === self.edit.packname && item.activeEmp === self.edit.empnum);
      if (matchingItem)
      {
        id = matchingItem.pid;
      }
      return id;
    },
    // ValidationTeleandEmail() {
    //   let self = this;
    //   let phone = self.company.teleNumber;

    //   for (let j in self.ComList) {
    //     if (phone == self.ComList[j].tele_Number) {
    //       alert("Telephone number is already used by another company");
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    editDuplicateCode() {
      let notSameList = this.ComList.filter((el) => el.company_ID != this.compID);
      return !notSameList.some((el) => el.companyCode.toLowerCase() == this.edit.code.toLowerCase());
    },

    editDuplicateName() {
      let notSameList = this.ComList.filter((el) => el.company_ID != this.compID);
      return !notSameList.some((el) => el.companyName.toLowerCase() == this.edit.name.toLowerCase());
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) 
      {
        $event.preventDefault();
      }
    },

    keymonitor: function (event) {
      if (event.key == "Backspace") 
      {
        this.edit.pwd = null;
        this.show1 = true;
      }
    },

    valNum() {
      let num = this.company.teleNumber;
      if (num.length >= 9 && num.length <= 11) 
      {
        this.company.teleNumber;
      } 
      else 
      {
        this.error.errorMessageph;
      }
    },

    async ShowHide() {
      let self = this;
      if (self.addroute == "addcompany") 
      {
        self.addcompanyadd = true;
      } 
      else 
      {
        self.addcompanyadd = false;
      }
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    async Editemployee() {
      try 
      {
        let self = this;
        let isitthere = self.$route.params.id;
        self.compID = parseInt(self.$route.params.id);
        let tempID = self.compID;
        if (isitthere != undefined) 
        {
          let temp = {
            company_ID: tempID,
          };
          await axios.post(`${self.url}Company/GetCompanyDetail`, temp)
          .then(function (response) {
            if(response.data.status == 0)
            {
              self.companyList = response.data.data;
              self.edit.name = self.companyList.companyName;
              self.edit.companysize = self.companyList.companySize;
              self.edit.businesstype = self.companyList.businesstype;
              self.edit.othertype = self.companyList.othertype;
              self.edit.teleNumber = self.companyList.tele_Number;
              self.edit.email = self.companyList.company_Email;
              self.edit.code = self.companyList.companyCode;
              self.edit.company_ID = self.companyList.company_ID;
              self.edit.contactname = self.companyList.contact_Name;
              self.edit.city = self.companyList.city;
              self.edit.country = self.companyList.country;
              self.edit.address = self.companyList.company_Address;
              self.edit.uid = self.companyList.username;
              self.GetUserIDByUserName(self.edit.uid);
              self.edit.pwd = self.companyList.password;
              self.registerDate = self.companyList.register_Date;
              self.expireddate = self.companyList.expired_Date;
              self.edit.packname = self.companyList.pkgName;
              self.getthatnumber(self.edit.packname);
              self.tempnum = self.companyList.activeEmp;
              self.edit.empnum = self.companyList.activeEmp;
              let lckshow = self.companyList.is_Locked;
              if (lckshow) 
              {
                self.showlock = lckshow;
                self.lockdisable = false;
              } 
              else 
              {
                self.showlock = lckshow;
                self.lockdisable = true;
              }
            }
            else
            {
              alert(response.data.message);
            }
          });
        }
      } 
      catch (error) 
      {
        alert(error);
      }
    },

    ValidationCompanyCode() {
      let self = this;
      let phone = self.company.code;
      for (let j in self.ComList) 
      {
        if (phone.toLowerCase() == self.ComList[j].companyCode.toLowerCase()) 
        {
          alert("This company code is already taken. Please use a different one.");
          return false;
        }
      }
      return true;
    },

    ValidationCompanyName() {
      let self = this;
      let phone = self.company.name;
      for (let j in self.ComList)
      {
        if (phone.toLowerCase() == self.ComList[j].companyName.toLowerCase())
        {
          alert("Company name already exists");
          return false;
        }
      }
      return true;
    },

    ValidationCompanyEmail() {
      let self = this;
      let email = self.company.email;
      for (let j in self.ComList)
      {
        if (email.toLowerCase() == self.ComList[j].company_Email.toLowerCase())
        {
          alert("This email has already been used by another user.Please use other email.");
          return false;
        }
      }
      return true;
    },
  
    ValidationCompanyEmailEdit() {
      let self = this;
      let email = self.edit.email.toLowerCase();
      let currentcompanyCode = self.edit.company_ID;
      // let currentEmployeeId = self.userid[0].user_ID;
      let FilterUserNameList = self.ComList.filter(
        (company) => company.company_ID != currentcompanyCode
      );
      for (let employee of FilterUserNameList)
      {
        if (email === employee.company_Email.toLowerCase())
        {
          return false;
        }
      }
      return true;
    },

    async checkingEmail() {
      let temp = {
        user_Email: this.company.email,
      };
      const response = await axios.post(`${this.url}Company/GetEmployeeEmail`,temp);
      return response.data.message;
    },

    async checkingEmailEdit() {
      let temp = {
        user_Email: this.edit.email,
        isEdit: "Edit",
        company_ID: this.compID,
      };
      const response = await axios.post(`${this.url}Company/GetEmployeeEmail`,temp);
      return response.data.message;
    },

    alphaOnly(event) {
      var key = event.keyCode;
      return (key >= 65 && key <= 90) || key == 8;
    },

    goOriginal() {
      this.$router.push({ name: "CompanyManagement" }).catch(() => {});
    },

    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";
      if (charactersArray.indexOf("a-z") >= 0)
      {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0)
      {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0)
      {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0)
      {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }
      for (let i = 0; i < this.size; i++)
      {
        tempPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return tempPassword;
    },

    generateUserID() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";
      if (charactersArray.indexOf("a-z") >= 0)
      {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0)
      {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      for (let i = 0; i < this.size; i++)
      {
        tempPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return tempPassword;
    },

    Checking() {
      if (this.company.teleNumber)
      {
        return this.company.teleNumber.length >= 9 ? true : false;
      }
    },

    async AddCompany() {
      let self = this;
      self.nameAdd = [(v) => !!v || "Company Name is required"];
      self.codeAdd = [(v) => !!v || "Company Code is required"];
      self.sizeAdd = [(v) => !!v || "Company Size is required"];
      self.BusinessAdd = [(v) => !!v || "Business type is required"];
      let validateothertype = true;
      if (self.company.businesstype == 30)
      {
        self.OthertypeAdd = [(v) => !!v || "Other type is required"];
        if (self.company.othertype == "" || self.company.othertype == null)
        {
          validateothertype = false;
        }
      }
      else
      {
        validateothertype = true;
      }
      self.phoneRules = [
        (v) => !!v || "Telephone number is required",
        (v) => self.Checking(v) || "Telephone number must be at lest 9 number",
      ];
      self.emailRules = [
        (v) => !!v || "Email is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ];
      let emailvalidation = self.Emailvalidate(self.company.email);
      self.countryAdd = [(v) => !!v || "Country is required"];
      self.cityAdd = [(v) => !!v || "City is required"];
      self.ContactAdd = [(v) => !!v || "Contact Name is required"];
      self.addressAdd = [(v) => !!v || "Address is required"];
      self.date10Add = [(v) => !!v || "Register Date is required"];
      self.date7Add = [(v) => !!v || "Expiry Date is required"];
      self.pnameAdd = [(v) => !!v || "Package Name is required"];
      self.enumAdd = [(v) => !!v || "Active Employees is required"];
      self.uidAdd = [(v) => !!v || "User Name is required"];
      self.pwdAdd = [(v) => !!v || "Password is required"];
      let tempvalidate = self.$refs.form.validate();
      // var tempvalidate = this.validateaddtrue();

      if (
        validateothertype == true &&
        emailvalidation == true &&
        tempvalidate == true &&
        self.Checking(3) &&
        self.company.name != null &&
        self.company.name != "" &&
        self.company.code != null &&
        self.company.code != "" &&
        self.company.teleNumber != null &&
        self.company.teleNumber != "" &&
        self.company.email != null &&
        self.company.email != "" &&
        self.company.country != null &&
        self.company.country != "" &&
        self.company.city != null &&
        self.company.city != "" &&
        self.company.address != null &&
        self.company.address != "" &&
        self.company.contactname != null &&
        self.company.contactname != "" &&
        self.company.packname != null &&
        self.company.packname != "" &&
        self.company.empnum != null &&
        self.company.empnum != "" &&
        self.date10 != null &&
        self.date10 != "" &&
        self.date7 != null &&
        self.date7 != "" &&
        self.uid != null &&
        self.uid != "" &&
        self.pwd != null &&
        self.pwd != ""
      )
      {
        // let CheckTelEmail = await self.ValidationTeleandEmail();
        let CheckName = self.ValidationCompanyName();
        if (CheckName)
        {
          let CheckCode = self.ValidationCompanyCode();
          if (CheckCode)
          {
            if (self.Checking(3))
            {
              let checkEmial = self.ValidationCompanyEmail();
              let validateusername = self.ValidateAccount();
              if (validateusername)
              {
                if (checkEmial)
                {
                // Add Expire date
                let nowdate = new Date();
                let tempdate = self.date7;
                let Local_Timezone = nowdate.getTimezoneOffset();
                let tempdate2 = self.date10;
                let id = self.whattheAddID();
                let companyUser = {
                  companyCode: self.company.code,
                  companyName: self.company.name,
                  companySize:parseInt(self.company.companysize),
                  businesstype: self.company.businesstype,
                  othertype: self.company.othertype,
                  tele_Number: self.company.teleNumber,
                  city: self.company.city,
                  country: self.company.country,
                  company_Email: self.company.email,
                  company_Address: self.company.address,
                  contact_Name: self.company.contactname,
                  username: self.uid,
                  password: self.pwd,
                  is_Locked: self.showlock,
                  activeFlag: self.disabled,
                  register_Date: tempdate2,
                  expired_Date: tempdate,
                  local_timezone: Local_Timezone,
                  package_ID: id,
                };
                self.loadingdialog = true;
                axios.post(`${self.url}Company/AddCompanyInfo`, companyUser)
                  .then(function (response) {
                    self.loadingdialog = false;
                    if (response.data.status == 0)
                    {
                      alert(response.data.message);
                      self.$router.push({ name: "CompanyManagement" }).catch(() => {});                      
                    }
                    else
                    {
                      alert(response.data.message);
                      self.error.errorMessagecode = "This company code is already taken. Please use a different one.";
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(error, "Add Company Fail", 12, "Low");
                    self.loadingdialog = false;
                  });
                }
                else
                {
                  alert("This email has already been used by another user.Please use other email.");
                }
              }
            }
          }
        }
      }
    },

    validateaddtrue() {
      let self = this;
      let emailvali = self.validateEmail(self.company.email);
      let phonevali = self.PhoneNumberValidation(self.company.teleNumber);
      if (
        self.company.code != null &&
        self.company.name != null &&
        emailvali == true &&
        self.company.city != null &&
        self.company.country != null &&
        phonevali == true &&
        self.company.address != null &&
        self.date7 != null &&
        self.date7 != "" &&
        self.date10 != null &&
        self.date10 != "" &&
        self.uid != null &&
        self.pwd != null &&
        self.uid != "" &&
        self.company.contactname != null
      )
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    validateerroradd() {
      let self = this;
      let emailvali = self.validateEmail(self.company.email);
      let phonevali = self.PhoneNumberValidation(self.company.teleNumber);
      if (
        self.company.code == null ||
        self.company.name == null ||
        self.company.teleNumber == null ||
        self.company.city == null ||
        self.company.country == null ||
        self.company.email == null ||
        self.company.address == null ||
        self.company.contactname != null ||
        self.date7 == null ||
        self.uid == null ||
        self.date10 == null ||
        self.uid == "" ||
        self.date7 == "" ||
        self.date10 == ""
      )
      {
        alert("Please fill in the required fields");
      }
      else if (!emailvali && !phonevali)
      {
        alert("Please fill the correct format");
        this.error.errorMessageemail = this.company.email != emailvali ? "Please fill correct email format" : this.company.email;
        this.error.errorMessageph = this.company.teleNumber != phonevali ? "Phone number must be 9 to 11 digits" : this.company.teleNumber;
      }
      else if (!emailvali)
      {
        alert("Please fill the correct format");
        this.error.errorMessageemail = this.company.email != emailvali ? "Please fill correct email format" : this.company.email;
      }
      else if (!phonevali)
      {
        alert("Please fill the correct format");
        this.error.errorMessageph = this.company.teleNumber != phonevali ? "Phone number must be 9 to 11 digits" : this.company.teleNumber;
      }
    },

    checkEmail(val) {
      let email = val;
      var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      var result = re.test(email) || false;
      return result;
    },

    async myupdatecompany() {
      try {
        let self = this;
        self.nameEdit = [(v) => !!v || "Company Name is required"];
        self.codeEdit = [(v) => !!v || "Company Code is required"];
        self.sizeEdit = [(v) => !!v || "Company Size is required"];
        self.BusinessEdit = [(v) => !!v || "Company Size is required"];
        let validateothertype = true;
        if (self.edit.businesstype == 30)
        {
          self.OthertypeEdit = [(v) => !!v || "Other type is required"];
          if (self.edit.othertype == "" || self.edit.othertype == null)
          {
            validateothertype = false;
          }
        }
        else
        {
          validateothertype = true;
        }
        self.phoneRulesEdit = [
          (v) => !!v || "Telephone number is required",
          (v) => v.length >= 9 || "Telephone number must be at lest 9 number",
        ];
        self.emailRulesEdit = [
          (v) => !!v || "Email is required",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ];
        self.countryEdit = [(v) => !!v || "Country is required"];
        self.cityEdit = [(v) => !!v || "City is required"];
        self.addressEdit = [(v) => !!v || "Address is required"];
        self.ContactEdit = [(v) => !!v || "Contact name is required"];
        self.registerDateEdit = [(v) => !!v || "Register Date is required"];
        self.expireddateEdit = [(v) => !!v || "Expiry Date is required"];
        self.pnameEdit = [(v) => !!v || "Package Name is required"];
        self.enumEdit = [(v) => !!v || "Active Employees is required"];
        self.uidEdit = [(v) => !!v || "User Name is required"];
        self.pwdEdit = [(v) => !!v || "Password is required"];

        // let editteleemail = self.ValidationTeleandEmailUpdate();
        let checkEmail = self.ValidationCompanyEmailEdit();
        var editvalidate = self.$refs.formEdit.validate();
        let validateusername = self.ValidateAccountForUpdate();
        if (
          validateothertype == true &&
          editvalidate == true &&
          self.edit.name != null &&
          self.edit.name != "" &&
          self.edit.code != null &&
          self.edit.code != "" &&
          self.edit.teleNumber != null &&
          self.edit.teleNumber != "" &&
          self.edit.email != null &&
          self.edit.email != "" &&
          self.edit.country != null &&
          self.edit.country != "" &&
          self.edit.city != null &&
          self.edit.city != "" &&
          self.edit.address != null &&
          self.edit.address != "" &&
          self.expireddate != null &&
          self.expireddate != "" &&
          self.edit.packname != null &&
          self.edit.packname != "" &&
          self.edit.empnum != null &&
          self.edit.empnum != "" &&
          self.edit.uid != null &&
          self.edit.uid != "" &&
          self.edit.pwd != null &&
          self.edit.pwd != "" &&
          self.edit.companysize != null &&
          self.edit.companysize != "" &&
          self.edit.contactname != "" &&
          self.checkEmail(self.edit.email) &&
          self.edit.contactname != null
        ) 
        {
          if (validateusername) 
          {
            let checkingName = self.editDuplicateName();
            if (checkingName) 
            {
              let checkingCode = self.editDuplicateCode();
              if (checkingCode)
              {
                if (checkEmail)
                {
                  self.loadingdialog = true;
                  self.errorMessages = "";
                  // Update Expire Date
                  let nowdate = new Date();
                  let [dd, mm, yy] = self.computedDateFormatted8.split("/");
                  nowdate.setDate(dd);
                  nowdate.setMonth(mm - 1);
                  nowdate.setFullYear(yy);
                  let mmm = null;
                  let mmn = nowdate.getMonth() + 1;
                  if (mmn < 10) 
                  {
                    mmm = "0" + mmn;
                  } 
                  else 
                  {
                    mmm = mmn;
                  }
                  let ddd = null;
                  let dd1 = nowdate.getDate();
                  if (dd1 < 10)
                  {
                    ddd = "0" + dd1;
                  }
                  else
                  {
                    ddd = dd1;
                  }
                  let tempdate = nowdate.getFullYear() + "-" + mmm + "-" + ddd + "T00:00:00";
                  let Local_Timezone = nowdate.getTimezoneOffset();
                  // Update Register Date
                  let nowdate2 = new Date();
                  let [dd2, mm2, yy2] = self.computedDateFormatted9.split("/");
                  nowdate2.setDate(dd2);
                  nowdate2.setMonth(mm2 - 1);
                  nowdate2.setFullYear(yy2);
                  let mmm2 = null;
                  let mmn2 = nowdate2.getMonth() + 1;
                  if (mmn2 < 10)
                  {
                    mmm2 = "0" + mmn2;
                  }
                  else
                  {
                    mmm2 = mmn2;
                  }
                  let ddd2 = null;
                  let dd12 = nowdate2.getDate();
                  if (dd12 < 10)
                  {
                    ddd2 = "0" + dd12;
                  }
                  else
                  {
                    ddd2 = dd12;
                  }
                  let tempdate2 = nowdate2.getFullYear() + "-" + mmm2 + "-" + ddd2 + "T00:00:00";
                  //let Local_Timezone = 390;
                  //alert(Local_Timezone)
                  let id = self.whattheEditID();
                  let temUpdateData = {
                    company_ID: self.compID,
                    companyCode: self.edit.code,
                    companyName: self.edit.name,
                    companySize:parseInt(self.edit.companysize),
                    businesstype: self.edit.businesstype,
                    othertype: self.edit.othertype,
                    tele_Number: self.edit.teleNumber,
                    city: self.edit.city,
                    country: self.edit.country,
                    company_Email: self.edit.email,
                    company_Address: self.edit.address,
                    contact_Name: self.edit.contactname,
                    is_Locked: self.showlock,
                    username: self.edit.uid,
                    password: self.edit.pwd,
                    register_Date: tempdate2,
                    expired_Date: tempdate,
                    local_timezone: Local_Timezone,
                    package_ID: id,
                  };
                
                  axios.post(`${self.url}Company/UpdateCompanyInfo`, temUpdateData)
                  .then(function (response) {
                    self.loadingdialog = false;
                    if (response.data.status === 0)
                    {
                      self.$router.push({ name: "CompanyManagement" }).catch(() => {});
                    }
                    else
                    {
                      alert(response.data.message);
                      self.error.errorMessagecode = "This company code is already taken. Please use a different one.";
                    }
                  })
                  .catch(function (error) {
                    self.LogTrace(error, "Update Company Fail", 12, "Medium");
                    self.loadingdialog = false;
                  });
                }
                else
                {
                  alert("This email has already been used by another user.Please use other email.");
                }
              }
              else
              {
                alert("This company code is already taken. Please use a different one.");
              }
            }
            else
            {
              alert("Company name already exists");
            }
          }
          else
          {
            self.loadingdialog = false;
            if (
              self.validateothertype == false ||
              self.edit.name == null ||
              self.edit.name == "" ||
              self.edit.code == null ||
              self.edit.code == "" ||
              self.edit.teleNumber == null ||
              self.edit.teleNumber == "" ||
              self.edit.email == null ||
              self.edit.email == "" ||
              self.edit.country == null ||
              self.edit.country == "" ||
              self.edit.city == null ||
              self.edit.city == "" ||
              self.edit.address == null ||
              self.edit.address == "" ||
              self.expireddate == null ||
              self.expireddate == "" ||
              self.edit.uid == null ||
              self.edit.uid == "" ||
              self.edit.pwd == null ||
              self.edit.pwd == "" ||
              self.edit.companysize == null ||
              self.edit.companysize == ""
            ) 
            {
              alert("Please fill all the fields");
            }
          }
        }
      } catch (error) {
        self.LogTrace(error, "Update Company Fail", 12, "Medium");
        self.loadingdialog = false;
      }
    },

    validateedittrue() {
      let self = this;
      let emailvali = self.validateEmail(self.edit.email);
      let phonevali = self.PhoneNumberValidation(self.edit.teleNumber);
      if (
        self.edit.code != null &&
        self.edit.code != "" &&
        self.edit.name != null &&
        self.edit.name != "" &&
        self.edit.teleNumber != null &&
        self.edit.teleNumber != "" &&
        self.edit.email != null &&
        emailvali == true &&
        self.edit.city != null &&
        self.edit.city != "" &&
        self.edit.country != null &&
        self.edit.country != "" &&
        phonevali == true &&
        self.edit.address != null &&
        self.edit.address != "" &&
        self.edit.uid != null &&
        self.edit.uid != "" &&
        self.expireddate != null &&
        self.expireddate != "" &&
        self.edit.pwd != "" &&
        self.edit.pwd != null &&
        self.edit.contactname != "" &&
        self.edit.contactname != null
      )
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    validateediterror() {
      let self = this;
      let emailvali = self.validateEmail(self.edit.email);
      let phonevali = self.PhoneNumberValidation(self.edit.teleNumber);
      if (
        self.edit.code == "" ||
        self.edit.code == null ||
        self.edit.name == "" ||
        self.edit.name == null ||
        self.edit.teleNumber == "" ||
        self.edit.teleNumber == null ||
        self.edit.city == null ||
        self.edit.city == "" ||
        self.edit.country == null ||
        self.edit.country == "" ||
        self.edit.email == null ||
        self.edit.email == "" ||
        self.edit.address == null ||
        self.edit.address == "" ||
        self.edit.uid == null ||
        self.edit.uid == "" ||
        self.edit.pwd == null ||
        self.edit.pwd == "" ||
        self.expireddate == null ||
        self.expireddate == "" ||
        self.edit.contactname != "" ||
        self.edit.contactname != null
      )
      {
        alert("Please fill in the required fields");
      }
      else if (!emailvali && !phonevali)
      {
        alert("Please fill the correct format");
        this.error.errorMessageemail =
          this.edit.email != emailvali
            ? "Please fill correct email format"
            : this.edit.email;
        this.error.errorMessageph =
          this.edit.teleNumber != phonevali
            ? "Phone number must be 9 to 11 digits"
            : this.edit.teleNumber;
      } else if (emailvali != true) {
        alert("Please fill the correct format");
        this.error.errorMessageemail =
          this.edit.email != emailvali
            ? "Please input valid email"
            : this.edit.email;
      } else if (phonevali != true) {
        alert("Please fill the correct format");
        this.error.errorMessageph =
          this.edit.teleNumber != phonevali
            ? "Phone number must be 9 to 11 digits"
            : this.edit.teleNumber;
      }
    },
  },
};
</script>

<style scoped>
.emp {
  margin-left: 150px;
}

.empID {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-family: "Kanit", sans-serif;
}
::v-deep .ct input {
  text-align: center;
}
.psw {
  margin-bottom: 0px;
  margin-top: 20px;
}
@media (min-width: 280px) and (max-width: 1025px) {
  .psw {
    /* margin-bottom: 30px ; */
    margin-top: -20px;
  }
}
</style>
