<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px;margin-top: 22px;" class="headertext">
        {{ $t("VersionAndRelease") }}
      </p>
    </div>
    <!-- <div style="background-color: #f0f0f7; margin-bottom: 1rem">
      <v-row>
        <p
          class="ml-2 mt-5 mb-0 headertext"
          text
          style="cursor: pointer;padding-left: 9px">
          {{ $t("VersionAndRelease") }}
        </p>
      </v-row> -->
      
      <v-card class="dialogmargin pb-4" style="margin-bottom: 5rem">
        <v-row style="margin-left: 1.5rem">
          <img
            src="@/assets/images/Mask Group 139 (5)@2x.png"
            width="30"
            height="30"
            class="mt-3 ml-4"
          />
          <p
            class="ml-1 mb-0"
            text
            style="
              cursor: pointer;
              font-size: 15px;
              margin-top: 1.2rem;
              padding-left: 9px;
              color: #f89d1f;
            "
          >
            {{ $t("VersionAndRelease") }}
          </p>
        </v-row>

        <v-row style="margin-left: 1.5rem; margin-right: 1.5rem">
          <p
            class="ml-2"
            text
            style="
              cursor: pointer;
              font-size: 15px;
              margin-top: 1.2rem;
              padding-left: 9px;
            "
          >
            {{ $t("CurrentVersion") }} {{ $t("January 10,2022") }}
          </p>
        </v-row>
        <v-row class="#F0F0F7 conrow">
          <v-col cols="12" xs="12" sm="12" md="5" lg="3" class="cardpadding2">
            <div class="CardLayoutOne">
              1.0.1<br />
              {{ $t("April 08,2021") }}
            </div>
            <div class="cardwith">
              <div class="row">
                <div class="card">
                  <p class="card-text">
                    <br />
                    1. {{ $t("companyManagement") }} <br />
                    2.{{ $t("userManagement") }} <br />
                    3. Log <br />
                    4. {{ $t("UsageReportByType") }} <br />
                    5. {{ $t("VersionAndRelease") }} <br />
                  </p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="3" class="cardpadding3">
            <div class="CardLayoutTwo">
              1.0.2<br />
              {{ $t("January 10,2022") }}
            </div>
            <div class="cardwith">
              <div class="row">
                <div class="card">
                  <p class="card-text2">
                    <br />
                    1. {{ $t("Help & Feedback") }} <br />
                    2.{{ $t("Default Language for customer companies") }} <br />
                    3. {{ $t("Announcement") }}
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 2rem">
          <div class="ml-3 my-5">
            <v-btn
              depressed
              class="text-capitalize"
              style="background-color: #f99d20; color: white"
              @click="showListChange()"
            >
              {{ $t("AllVersion") }}
              <img
                class="ml-2 mt-1"
                width="15"
                height="6"
                :src="showLists ? Advanceimage.down : Advanceimage.up"
                alt
            /></v-btn>
          </div>
        </v-row>
        <v-row
          v-if="showLists"
          style="margin-left: 2.2rem; margin-right: 2.2rem"
        >
          <v-col cols="12" md="12" sm="12">
            <v-card class="mx-auto mb-3">
              <v-row class="ml-1 mr-1">
                <v-card-subtitle class="pb-0" style="color: #f99d20"
                  >1.0.2</v-card-subtitle
                >
                <v-spacer></v-spacer>
                <v-card-subtitle class="pb-0" style="color: #f99d20">{{
                  $t("January 10,2022")
                }}</v-card-subtitle>
              </v-row>

              <v-card-text class="text--primary">
                <div>
                  1. {{ $t("Help & Feedback") }} <br />
                  2.{{ $t("Default Language for customer companies") }} <br />
                  3. {{ $t("Announcement") }}
                </div>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto mb-3 pt-3">
              <v-row class="ml-1 mr-1">
                <v-card-subtitle class="pb-0" style="color: #f99d20"
                  >1.0.1</v-card-subtitle
                >
                <v-spacer></v-spacer>
                <v-card-subtitle class="pb-0" style="color: #f99d20">{{
                  $t("April 08,2021")
                }}</v-card-subtitle>
              </v-row>

              <v-card-text class="text--primary">
                <div>
                  1. {{ $t("companyManagement") }} <br />
                  2. {{ $t("userManagement") }} <br />
                  3. Log <br />
                  4. {{ $t("UsageReportByType") }} <br />
                  5. {{ $t("VersionAndRelease") }} <br />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- </div> -->
      </v-card>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      Advanceimage: {
        up: require("@/assets/images/UPP.png"),
        down: require("@/assets/images/Group 3494_0.png"),
      },
      customImgHeight: "100%",
      rememberme: "false",
      showLists: false,
    };
  },
  mounted() {},
  methods: {
    showListChange() {
      let self = this;
      self.showLists = !self.showLists;
    },
  },

  watch: {},
};
</script>

<style scoped>
.CardLayoutOne {
  width: 244px;
  height: 50px;
  background-color: #06b11d;
  padding-top: 0.3rem;
  color: white;
  text-align: center;
  font-size: 13px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.CardLayoutTwo {
  width: 244px;
  height: 50px;
  background-color: #ff4b55;
  color: white;
  padding-top: 0.3rem;
  text-align: center;
  font-size: 13px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.CardLayoutThree {
  width: 244px;
  height: 50px;
  background-color: #0fa7d8;
  font-size: 13px;
  color: white;
  padding-top: 0.3rem;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.CardLayoutFour {
  width: 244px;
  height: 50px;
  background-color: #f99d20;
  color: white;
  padding-top: 0.3rem;
  font-size: 13px;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.row {
  /* align-items: stretch;
  display: flex;
  flex-wrap: nowrap; */
  overflow-x: auto;
  overflow-y: auto;
}
.card {
  /*float: left;*/
  max-width: 100%;
  /* padding-top: 0.2rem; */
  padding-left: 0.75rem;
  padding-right: 0.06rem;
  margin-bottom: 2rem;
  border: 0;
  flex-basis: 100%;
  /* flex-grow: 0;
  flex-shrink: 0; */
  max-height: 280px;
}

.card > img {
  margin-bottom: 0.75rem;
}

.card-text {
  font-size: 12px;
  border-left: #06b11d 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 280px;
}
.card-text2 {
  font-size: 12px;
  border-left: #ff4b55 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 280px;
}
.card-text3 {
  font-size: 12px;
  border-left: #0fa7d8 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.card-text4 {
  font-size: 12px;
  border-left: #f99d20 5px solid;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
.cardpadding1 {
  margin-top: 1rem;
}
.cardpadding2 {
  margin-top: 8rem;
}
.cardpadding3 {
  margin-top: 4rem;
}
.conrow {
  margin-left: 2.5rem;
}
.cardwith {
  width: 230px;
}
@media only screen and (max-width: 768px) {
  .conrow {
    margin-left: 0rem !important;
  }
  .cardpadding1 {
    margin-top: 1rem !important;
  }
  .cardpadding2 {
    margin-top: 1rem !important;
  }
  .cardpadding3 {
    margin-top: 1rem !important;
  }
  .cardpadding4 {
    margin-top: 1rem !important;
  }
}
@media only screen and (min-width: 375px) {
  .conrow {
    margin-left: 1.8rem !important;
  }
}
@media (min-width: 1024px) and (max-width: 1025px) {
  .cardwith {
    width: 200px !important;
  }
  .CardLayoutOne {
    width: 214px !important;
  }
  .CardLayoutTwo {
    width: 214px !important;
  }
  .CardLayoutThree {
    width: 214px !important;
  }
  .CardLayoutFour {
    width: 214px !important;
  }
}
@media (min-width: 1264px) and (max-width: 1285px) {
  .conrow {
    margin-left: 0rem !important;
  }
  .cardwith {
    width: 200px !important;
  }
  .CardLayoutOne {
    width: 210px !important;
  }
  .CardLayoutTwo {
    width: 210px !important;
  }
  .CardLayoutThree {
    width: 210px !important;
  }
  .CardLayoutFour {
    width: 210px !important;
  }
}
</style>
